import React from 'react';
import { GlobalService } from '../services/GlobalService';
import { Evento } from 'passemix-lib/src/models/Evento';
import { Carrinho } from '../models/Carrinho';

const contextDefault = {
    carrinho: new Carrinho(),
    setCarrinho: (state: Carrinho) => {},
    logado: GlobalService.isAutenticado(),
    setLogado: (state: boolean) => {},
    evento: new Evento(),
    setEvento: (state: Evento) => {},
    logout: () => {}
};
 
const GlobalContext = React.createContext(contextDefault);

export default GlobalContext;