import { parseJSON, addHours, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale'

const vanillaMasker = require('vanilla-masker');

export class UtilsHelper {
    
    public static formatCurrency(value: any) {
        // eslint-disable-next-line
        var val = new Number(value);
        return val.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    public static formatRate(value: any) {
        if (value !== undefined)
            return value.toLocaleString('pt-BR', {style: 'percent', minimumFractionDigits: 5 });
        else
            return '-';
    }

    public static formatDateTime(value: any, formatting: string) 
    {
        if (value !== null && value !== undefined) 
        {
            let parsedDateTime = parseJSON(value);
            let znDateTime = utcToZonedTime(parsedDateTime, 'America/Sao_Paulo');
            let dateTimeAddHours = addHours(znDateTime, 3);
            let date = format(dateTimeAddHours, formatting, {locale: ptBR});

            if (date.split(' ')[0] !== '31/12/1900')
                return date;
            else
                return '-';
        } else {
            return '-';
        }
    }

    public static formatTelefone(value: any)
    {
        if (!value)
            return '';

        const telefoneFixoMask = '(99) 9999-9999';
        const telefoneCelularMask = '(99) 99999-9999';

        const telefonePattern: string = value.toString().length > 14 ? telefoneCelularMask : telefoneFixoMask;
        return vanillaMasker.toPattern(value, telefonePattern);
    }

    public static formatCpf(value: any)
    {
        if (!value)
            return '';

        const cpfMask = '999.999.999-99';
        return vanillaMasker.toPattern(value, cpfMask);
    }

    public static formatCpfCnpj(value: any)
    {
        if (!value)
            return '';

        const cpfMask = '999.999.999-99';
        const cnpjMask = '99.999.999/9999-99';

        const cpfCnpjPattern: string = value.toString().length > 14 ? cnpjMask : cpfMask;
        return vanillaMasker.toPattern(value, cpfCnpjPattern);
    }

    public static ToBoolean(value: string | number | boolean): boolean
    {
        return [true, 'true', 'True', 'TRUE', '1', 1].includes(value);
    }

}