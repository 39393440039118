import React from 'react';
import ContaComponent from '../components/Conta/ContaComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const ContaContainer: React.FC = () => {
	
	const conteudo = 
	<React.Fragment>
		<ContaComponent/>
	</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default ContaContainer;