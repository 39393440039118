import React from 'react';
import { useParams } from 'react-router-dom';
import ConfirmacaoComponent from '../components/Confirmacao/ConfirmacaoComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const ConfirmacaoContainer: React.FC = () => {
	
	const { idVenda } = useParams<{ idVenda: string }>();
	
	const conteudo =
		<React.Fragment>
			<ConfirmacaoComponent idVenda={idVenda} />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default ConfirmacaoContainer;