import React from 'react';
import * as Yup from "yup"
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import { Password } from 'primereact/password';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { isValid as isValidCpf } from "@fnando/cpf";
import { Message } from 'primereact/message';
import { ClienteService } from '../../services/ClienteService';

export interface ICadastroForm {
    cliente: Cliente;
    onSave: any;
}

const innerForm = (props: ICadastroForm & FormikProps<Cliente>) => {
    
    const { values } = props;

    const onChangeTelefone = (event: any) => {
        const { name, value } = event.target;
        let telefoneFormatado = UtilsHelper.formatTelefone(value);
        props.setFieldValue(name, telefoneFormatado);
    }

    const onChangeCpf = (event: any) => {
        const { name, value } = event.target;
        let cpfCnpjFormatado = UtilsHelper.formatCpf(value);
        props.setFieldValue(name, cpfCnpjFormatado);
    }

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2">A sua senha deve conter:</p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{lineHeight: '1.5'}}>
                <li>No mínimo 8 caracteres </li>
                <li>Pelo menos uma letra maiúscula</li>
                <li>Pelo menos uma letra minúscula</li>
                <li>Pelo menos um número</li>
            </ul>
        </React.Fragment>
    );

    return (
        <Form>
            <div className="p-grid p-fluid p-text-left">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Nome completo</div>
                    <InputText name="nome" value={values.nome} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="nome" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">CPF</div>
                    <InputText type="tel" name="cpf" value={values.cpf} onChange={onChangeCpf} />
                    <small className="invalid"><ErrorMessage name="cpf" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Celular</div>
                    <InputText type="tel" name="telefone" value={values.telefone} onChange={onChangeTelefone} />
                    <small className="invalid"><ErrorMessage name="telefone" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">E-mail</div>
                    <InputText type="email" name="email" value={values.email} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="email" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Senha</div>
                    <Password 
                        name="senha"
                        value={values.senha}
                        onChange={props.handleChange}
                        promptLabel="Digite sua senha"
                        weakLabel='Senha com segurança fraca'
                        mediumLabel='Senha com segurança mediana'
                        strongLabel='Senha segura'
                        strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                        toggleMask
                        feedback={true}
                        footer={footer}
                    />
                    <small className="invalid"><ErrorMessage name="senha" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Confirmar Senha</div>
                    <Password 
                        name="confirmacaoSenha"
                        value={values.confirmacaoSenha}
                        onChange={props.handleChange}
                        promptLabel="Confirme sua senha"
                        weakLabel='Senha com segurança fraca'
                        mediumLabel='Senha com segurança mediana'
                        strongLabel='Senha segura'
                        strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                        toggleMask
                        feedback={true}
                        footer={footer}
                    />
                    <small className="invalid"><ErrorMessage name="confirmacaoSenha" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div>
                        <Message severity="info" text="Ao realizar o cadastro, você afirma está ciênte dos termos de uso." />
                    </div>
                    <small className="invalid"><ErrorMessage name="termos" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <Button type="submit" label="Criar conta"className='p-button-primary' disabled={props.isSubmitting} loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório")
    .min(8, 'Preencha seu nome completo'),
    cpf: Yup.string().required("Campo obrigatório")
    .test("cpf-valido", "CPF inválido",(value) => { return value !== undefined ? isValidCpf(value) : false })
    .test('checkCpfUnique', 'Este CPF já está registado.', async (value) => {
        if(value !== undefined && isValidCpf(value))
        {
            let service = new ClienteService();
            let response = await service.verificarCpf(value);
            return (response) ? false : true;
        }
        return true;
    }),
    telefone: Yup.string().required("Campo obrigatório"),
    email: Yup.string().email("Email inválido").required("Campo obrigatório")
    .matches(/@(?!gmai\.|hotmai\.|iclou\.)\w+\./, 'Preencha um e-mail válido')
    .test('checkEmailUnique', 'Este e-mail já está registado.', async (value) => {
        if(value !== undefined && value.length > 5)
        {
            let service = new ClienteService();
            let response = await service.buscarClientePorEmailOuCpf(value);
            return (response) ? false : true;
        }
        return true;
    }),
    senha: Yup.string()
        .required("Campo obrigatório")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Informe uma senha forte'),
    confirmacaoSenha: Yup.string()
        .required("Campo obrigatório")
        .oneOf([Yup.ref('senha'), null], "A confirmação deve ser igual a senha.")
});

const CadastroForm = withFormik<ICadastroForm, Cliente>({

    // Mapeamento das propriedades para os campos
    mapPropsToValues: (props) => {
        return props.cliente;
    },

    validationSchema: validationForm,
    validateOnChange: false,

    handleSubmit: async (values, { props, resetForm }) => {
        try 
        {
            let service = new ClienteService();
            let response = await service.cadastrar(values);

            if(response !== undefined)
                resetForm();

            props.onSave(response);
        }
        catch (error) {
            console.log(error)
        }
    }
})(innerForm);

export default CadastroForm;