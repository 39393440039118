import React from 'react';
import LayoutComponent from '../components/Layout/LayoutComponent';
import TermosComponent from '../components/Termos/TermosComponent';

const TermosContainer: React.FC = () => {
	
	const conteudo = 
	<React.Fragment>
		<TermosComponent />
	</React.Fragment>

	return (
		<LayoutComponent content={conteudo} />
	);
}

export default TermosContainer;