import React from 'react';
import { useParams } from 'react-router-dom';
import VendaComponent from '../components/Venda/VendaComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const VendaContainer: React.FC = () => {
	
	const { idVenda } = useParams<{ idVenda: string }>()
	
	const conteudo =
		<React.Fragment>
			<VendaComponent idVenda={idVenda} />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default VendaContainer;