import { Lote } from "./Lote";

export class Ticket {
    public id: number;
    public codTicket: number;
    public codBarras: string;
    public participante: string;
    public documento: string;
    public dataCheckin: Date;
    public status: string;
    public participanteAlterado: boolean;
    public data: Date;
    public dataAlteracao: Date;
    public modelo: string;
    public observacao: string;
    public isCancel: boolean;
    public isAuthentic: boolean;
    public lote: Lote;

    public valido: boolean;
}