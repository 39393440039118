
import React from 'react';
import LayoutComponent from '../components/Layout/LayoutComponent';
import EventosComponent from '../components/Evento/EventosComponent';

const CheckoutContainer: React.FC = () => {
	
	const conteudo = 
	<React.Fragment>
		<EventosComponent />
	</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default CheckoutContainer;