import React, { useContext, useEffect, useState } from 'react';
import ConfirmacaoForm from './ConfirmacaoForm';
import { ClienteService } from '../../../services/ClienteService';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import GlobalContext from '../../../helpers/GlobalContext';
import { useHistory } from 'react-router-dom';
import './style.css';


const ConfirmacaoComponent: React.FC = () => {

	const history = useHistory();
	const { logout } = useContext(GlobalContext);
	const [cliente, setCliente] = useState<Cliente>(new Cliente());

	const onSubmited = () => {
		let state = history.location.state;

		if(state === 'checkout')
		{
			history.push("/checkout");
		}else {
			history.push("/conta");
		}
	}

	const obterCliente = async () => {
		try {
			let service = new ClienteService();
			let response = await service.obterCliente();
			setCliente(response);
		}
		catch (error: any) {

			if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				console.log('Falha ao carregar dados');
			}
		}
	}

	useEffect(() => {
		obterCliente();
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className='container-confirmacao'>
			<div className='confirmacao-content'>
				<div>
					<h3>Complete seu cadastro</h3>
					<div className='text-instrucao'>
						<p>Antes de finalizar sua compra, você precisa completar seu cadastro.</p>
						<p>Logado como {cliente.nome} ({cliente.email})</p>
					</div>
					
				</div>
				<ConfirmacaoForm cliente={cliente} onSubmited={onSubmited} />
			</div>
		</div>
	);
}

export default ConfirmacaoComponent;