import React, { useContext, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import logo from '../../content/img/logo.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import CartaoVisa from '../../content/img/icones-pagamento/visa.svg'
import CartaoMaster from '../../content/img/icones-pagamento/master.svg'
import CartaoElo from '../../content/img/icones-pagamento/elo.svg'
import CartaoDiners from '../../content/img/icones-pagamento/diners.svg'
import CartaoHiper from '../../content/img/icones-pagamento/hiper.svg'
import CartaoAmex from '../../content/img/icones-pagamento/amex.svg'
import Pix from '../../content/img/icones-pagamento/pix.svg'
import GlobalContext from '../../helpers/GlobalContext';
import { format, utcToZonedTime } from 'date-fns-tz';

export interface ILayoutComponent {
	content: React.ReactFragment
}

const LayoutComponent: React.FC<ILayoutComponent> = (props) => {

	const menu = useRef<Menu>(null);
	let history = useHistory();
	let location = useLocation();
	const {logado, logout} = useContext(GlobalContext);
	const [showSideBar, setShowSideBar] = useState(false);
	const [ano] = useState(format(utcToZonedTime(new Date(), 'America/Sao_Paulo'), "yyyy"));

	ReactGA.initialize('UA-72925122-1');
	ReactGA.pageview(location.pathname);
	
	ReactGA4.initialize("G-1N7RPBS7X0");
	ReactGA.send({ hitType: "pageview", page: location.pathname });

	const sair = () => {
		logout();
		history.push("/eventos");
	}

	let items = [
		{label: 'Minha Conta', icon: 'pi pi-fw pi-user', command:()=>{ history.push("/minha-conta"); }},
		{label: 'Meus Ingressos', icon: 'pi pi-fw pi-ticket', command:()=>{ history.push("/meus-ingressos"); }},
		{label: 'Área Comissário', icon: 'pi pi-fw pi-users', command:()=>{ history.push("/area-comissario"); }},
		{label: 'Explorar eventos', icon: 'pi pi-fw pi-search', command:()=>{ history.push("/eventos"); }},
		{label: 'Sair', icon: 'pi pi-fw pi-sign-out', command:()=>{ sair(); }} 
	]

	return (
		<>
			<div className="container-nav-top">
				<div className="p-grid">
					<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
						<div className="p-grid">
							<div className="p-col-6 p-md-6 p-lg-6 p-xl-6 p-pt-4 p-pl-3">
								<a href="/"><img className="logo" src={logo} alt="Logo" /></a>
							</div>
							<div className="p-lg-6 p-xl-6 p-d-none p-d-lg-inline p-d-xl-inline" style={{paddingTop: '20px'}}>
								<div className="p-d-flex p-jc-end" id='menu-container'>
									{
										logado ?
										<>
											<Avatar icon="pi pi-user" shape="circle" className='p-mr-4 avatar-menu' onClick={(event) => menu.current?.toggle(event)}/>
											<Menu model={items} popup ref={menu} popupAlignment="right" />
										</>
										:
										<div className="p-mr-4">
											<Button label="EVENTOS" className="p-button-text p-button-inverse" onClick={() => history.push("/eventos")} />
											<Button label="ENTRAR" className="p-button-text p-button-inverse" onClick={() => history.push("/login")} />
										</div>
									}
								</div>
							</div>
							<div className="p-col-6 p-md-6 p-d-lg-none p-d-xl-none p-text-right p-pr-4 p-pt-3">
								<Button icon="pi pi-bars" className="p-button-text p-button-lg p-button-inverse" onClick={() => setShowSideBar(true)} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="container-content">
					{props.content}
				</div>
			</div>
			<div className="container-footer">
				<div className="grid">
					<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
						<div className="p-grid">
							<div className="p-col-12 p-md-12 p-lg-4 p-xl-4 p-text-left">
								<ul style={{ listStyle: "none" }}>
									<li><Link to="/">Sobre nós</Link></li>
									<li><a href="https://passemix.com.br/termos" target="_blank" rel="noreferrer">Termos de uso</a></li>
									<li><Link to="/contato">Contato</Link></li>
									<li><Link to="/contato">Dúvidas frequentes</Link></li>
								</ul>
							</div>
							<div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
								<ul style={{ listStyle: "none" }}>
									<li><Link to="/login">Entrar</Link></li>
									<li><Link to="/conta">Meus ingressos</Link></li>
									<li><Link to="/login">Esqueci minha senha</Link></li>
								</ul>
							</div>
							<div className="p-col-12 p-md-12 p-lg-4 p-xl-4 p-text-center">
								<h4>Formas de pagamento</h4>
								<img src={CartaoVisa} alt="Cartão de crédito visa" className="img-cartoes" />
								<img src={CartaoMaster} alt="Cartão de crédito master" className="img-cartoes" />
								<img src={CartaoElo} alt="Cartão de crédito elo" className="img-cartoes" />
								<img src={CartaoDiners} alt="Cartão de crédito diners" className="img-cartoes" />
								<img src={CartaoHiper} alt="Cartão de crédito hiper" className="img-cartoes" />
								<img src={CartaoAmex} alt="Cartão de crédito amex" className="img-cartoes" />
								<img src={Pix} alt="Pix" className="img-cartoes" />
							</div>
						</div>
						<div className="footer-copy">
							Copyright © {ano} PasseMix. Todos os direitos reservados.
						</div>
					</div>
				</div>
			</div>
			
			<Sidebar visible={showSideBar} position="left" style={{width:'17em'}} showCloseIcon={false} onHide={() => setShowSideBar(false)}>
				<div className="sidebar-menu">
					<ul style={{ listStyle: "none" }}>
						<li><i className="pi pi-home p-mr-2" style={{'fontSize': '1.2em'}} /> <Link to="/">Inicio</Link></li>
						<li><i className="pi pi-search p-mr-2" style={{'fontSize': '1.2em'}} /> <Link to="/eventos">Explorar eventos</Link></li>
						<li> <i className="pi pi-list p-mr-2" style={{'fontSize': '1.2em'}} /> <a href="https://passemix.com.br/termos" target="_blank" rel="noreferrer">Termos de uso</a></li>
						<li><i className="pi pi-send p-mr-2" style={{'fontSize': '1.2em'}} /> <Link to="/contato">Contato</Link></li>
						<li><i className="pi pi-ticket p-mr-2" style={{'fontSize': '1.2em'}} /> <span onClick={() => history.push("/meus-ingressos")}>Meus Ingressos</span></li>
						{
							logado ?
							<>
								<li><i className="pi pi-user p-mr-2" style={{'fontSize': '1.2em'}} /> <span onClick={() => history.push("/minha-conta")}>Minha Conta</span></li>							
								<li><i className="pi pi-users p-mr-2" style={{'fontSize': '1.2em'}} /> <span onClick={() => history.push("/area-comissario")}>Área Comissário</span></li>
								<li><i className="pi pi-sign-out p-mr-2" style={{'fontSize': '1.2em'}} /> <span onClick={sair}>Sair</span></li>
							</>
							: ''
						}
					</ul>
				</div>
			</Sidebar>
		</>
	);
}

export default LayoutComponent;