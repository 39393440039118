import { ResponseHelper } from "../helpers/ResponseHelper";
import { Ticket } from "passemix-lib/src/models/Ticket";
import api from "./Api";

export class TicketService {
    
    private endpoint = 'v1/tickets';

    public async atualizar(ticket: Ticket) {
        return await api.put(`${this.endpoint}`, ticket)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

}