import { ResponseHelper } from "../helpers/ResponseHelper";
import { Lote } from "passemix-lib/src/models/Lote";
import api from "./Api";

export class LoteService {
    
    private endpoint = 'v1/lotes';

    public async obterPorEvento(idEvento: number): Promise<Lote[]> {
        return await api.get<Lote>(`${this.endpoint}/evento/${idEvento}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorEventoComissario(idEvento: number, chaveComissario: string): Promise<Lote[]> {
        return await api.get<Lote>(`${this.endpoint}/evento/${idEvento}/comissario/${chaveComissario}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorId(idLote: string): Promise<Lote> {
        return await api.get<Lote>(`${this.endpoint}/${idLote}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}