import React from 'react';
import * as Yup from "yup";
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

export interface ISenhaForm
{
    onLogin: any;
    onRecuperarSenha: any;
}

const innerForm = (props: ISenhaForm & FormikProps<{senha: string}>) => 
{
    const { values } = props;

    return (
        <Form>
            <div className="p-fluid p-grid p-text-left">
                <div className="p-col-12">
                    <div className="label">Senha</div>
                    <Password name="senha" value={values.senha} onChange={props.handleChange} toggleMask  feedback={false} />
                    <small className="invalid"><ErrorMessage name="senha" /></small>
                </div>
                <div className="p-col-6 p-pt-2 p-pr-2">
                    <Button type='button' label="Esqueceu a senha?" className="p-button-sm p-button-link button-change-profile" onClick={props.onRecuperarSenha} />
                </div>
                <div className="p-col-6 p-pt-2">
                    <Button type="submit" label="Entrar" className='p-button-primary' disabled={ props.isSubmitting } loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    senha: Yup.string().required("Campo obrigatório")
});


const LoginForm = withFormik<ISenhaForm, {senha: string}>({
    
    // Mapeamento das propriedades para os campos
    mapPropsToValues: () => 
    {
        return {senha: ''};
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props }) => 
    {
        await props.onLogin(values.senha);
    }    
})(innerForm);

export default LoginForm;