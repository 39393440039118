import { AxiosResponse } from "axios";

export class ResponseHelper
{
    static sucesso(response: AxiosResponse<any>)
    {
        if(response.data)
            return response.data;
        
    }

    static erro(error: any)
    {
        return error.response;
    }
}