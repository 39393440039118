import React from 'react';
import ConfirmacaoComponent from '../components/Conta/Confirmacao/ConfirmacaoComponent';

const ContaConfirmacaoContainer: React.FC = () => {

	return (
		<ConfirmacaoComponent />
	);
}

export default ContaConfirmacaoContainer;