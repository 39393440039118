import React from 'react';
import LayoutComponent from '../components/Layout/LayoutComponent';
import VendasComponent from '../components/Venda/VendasComponent';

const VendasContainer: React.FC = () => {

	const conteudo =
		<React.Fragment>
			<VendasComponent />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default VendasContainer;