import * as Yup from "yup"
import Swal from 'sweetalert2'
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { isValid as isValidCpf } from "@fnando/cpf";
import { PaymentDataViewModel } from "passemix-lib/src/viewmodels/PaymentDataViewModel";
import { VendaService } from "../../services/VendaService";
import { Carrinho } from "../../models/Carrinho";

declare var PagSeguro: any;

export interface ICartaoForm {
    mp: any;
    publicKeyPagBank: string;
    carrinho: Carrinho;
    deviceId: string;
    ip: string;
    processando: boolean;
    identificacaoValida: boolean;
    onSubmited: any;
}

const innerForm = (props: ICartaoForm & FormikProps<PaymentDataViewModel>) => {
    
    const { values } = props;

    const onChangeCartao = async (event: any) => {
        const { value } = event.target;
        let formatado = value.replace(/\s+/g, '');
		props.setFieldValue('numeroCartao', formatado);

        if(formatado.length < 6)
            props.setFieldValue('metodoPagamento', null)

		if(formatado.length === 6 || formatado.length >= 15)
			await obterMeioPagamentoMp(value);
	}

    const onChangeParcelas = (value: number) => {
		props.setFieldValue('parcela', value)
    }

    // Metodos requisicao MP

	// Busca o meio de pagamento (bandeira)
	const obterMeioPagamentoMp = async (numero: string) => {
		let bin = numero.replace(/[ .-]/g, '').slice(0, 6);
		let paymentMethods = await props.mp.getPaymentMethods({ bin: bin })
        
        props.setFieldValue('metodoPagamento', paymentMethods.results[0])
        await obterParcelasMp(bin);
	}

    // Busca as parcelas
	const obterParcelasMp = async (bin: string) => {
        let total = props.carrinho.itens.reduce((a, b) => a + b.lote.preco + (props.carrinho.evento.isTaxaOnlineCredito ? 
            ((b.lote.preco * props.carrinho.evento.taxaOnlineCredito) < props.carrinho.evento.tarifaMinimaOnline ? 
            props.carrinho.evento.tarifaMinimaOnline : (b.lote.preco * props.carrinho.evento.taxaOnlineCredito)) : 0), 0);

		const installments = await props.mp.getInstallments({ bin: bin, amount: `${total}`, locale: 'pt-BR'})
        props.setFieldValue('parcelas', installments[0].payer_costs)
	}

    return (
        <Form onSubmit={props.handleSubmit}>
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="p-field">
                        <label className="text-label-cartao">Número do cartão</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                            {
                                !values.metodoPagamento ? 
                                <i className="pi pi-credit-card" /> : <i><img src={`${values.metodoPagamento.secure_thumbnail}`} height="15px" alt="Bandeira" /></i>
                            }
                            </span>
                            <InputText type="tel" name="numeroCartao" onChange={(e) => onChangeCartao(e)} maxLength={19} />
                        </div>
                        <small className="invalid"><ErrorMessage name="numeroCartao" /></small>
                    </div>
                </div>
                <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                    <div className="p-field">
                        <label className="text-label-cartao">Data de vencimento</label>
                        <div className="p-d-flex">
                            <div className="p-mr-1">
                                <InputText type="tel" name="mesExpiracaoCartao" value={values.mesExpiracaoCartao} onChange={props.handleChange} maxLength={2} placeholder="02" />
                                <small className="invalid"><ErrorMessage name="mesExpiracaoCartao" /></small>
                            </div>
                            <div>
                                <InputText type="tel" name="anoExpiracaoCartao" value={values.anoExpiracaoCartao} onChange={props.handleChange} maxLength={4} placeholder="2027"/>
                                <small className="invalid"><ErrorMessage name="anoExpiracaoCartao" /></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                    <div className="p-field">
                        <label className="text-label-cartao">Código de segurança</label>
                        <span className="p-input-icon-left">
                            <i className="pi pi-lock" />
                            <InputText type="tel" name="codigoSeguranca" value={values.codigoSeguranca} onChange={props.handleChange} maxLength={4} placeholder="999" />
                        </span>
                        <small className="invalid"><ErrorMessage name="codigoSeguranca" /></small>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                    <div className="p-field">
                        <label className="text-label-cartao">Nome completo</label>
                        <span className="p-input-icon-left">
                            <i className="pi pi-credit-card" />
                            <InputText type="text" name="nomeCartao" value={values.nomeCartao} onChange={props.handleChange} />
                        </span>
                        <small className="text-info-sm">É o nome do titular igual etá estampado no cartão.</small>
                        <small className="invalid"><ErrorMessage name="nomeCartao" /></small>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                    <div className="p-field">
                        <label className="text-label-cartao">CPF do titular</label>
                        <span className="p-input-icon-left">
                            <i className="pi pi-id-card" />
                            <InputText type="tel" name="numeroCpf" value={values.numeroCpf} maxLength={11} onChange={props.handleChange} placeholder="12345678900"/>
                        </span>
                        <small className="invalid"><ErrorMessage name="numeroCpf" /></small>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                    <div className="p-field">
                        <label className="text-label-cartao">Parcelas</label>
                        <Dropdown
                            name="parcela"
                            value={values.parcela} 
                            options={values.parcelas} 
                            onChange={(e) => onChangeParcelas(e.value)} 
                            optionLabel="recommended_message" 
                            optionValue="installments" 
                            placeholder="Selecione a quantidade de parcelas" 
                            emptyMessage="Preencha os dados do cartão"
                        />
                        <small className="invalid"><ErrorMessage name="parcela" /></small>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-xl-12 p-lg-12">
                    <Button type="submit" label={ (props.isSubmitting || props.processando) ? 'Finalizando' : 'Finalizar Compra' } className="button-comprar p-button-lg p-mt-4" disabled={props.isSubmitting || props.processando || !props.identificacaoValida} loading={props.isSubmitting || props.processando} />
                </div>
                <div className="p-col-12">
                    <Divider align="left">
                        <span className="text-dividers">Ajuda</span>
                    </Divider>
                    <div className="text-checkout-instrucoes">
                        <ul>
                            <li>Número do cartão: É o numero estampado no cartão de crédito;</li>
                            <li>Data de vencimento: É a data de vencimento estampada no cartão de crédito;</li>
                            <li>Código de segurança: É o código de segurança estampado no verso do cartão de crédito;</li>
                            <li>Nome completo: É o nome do titular igual etá estampado no cartão de crédito;</li>
                            <li>CPF do titular: É o CPF do titular do cartão de crédito;</li>
                            <li>Parcelas: É a quantidade de parcelas que a compra será paga;</li>
                            <li>Cartões de débito não são aceitos;</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    numeroCartao: Yup.string()
                    .required("Campo obrigatório")
                    .matches(/^[0-9]+$/, "Apenas números")
                    .min(15, "Quantidade mínima de 15 dígitos")
                    .max(16, "Quantidade máxima de 16 dígitos"),

    mesExpiracaoCartao: Yup.string()
                    .required("Campo obrigatório")
                    .matches(/^[0-9]+$/, "Apenas números")
                    .min(2, "Quantidade mínima de 2 dígitos")
                    .max(2, "Quantidade máxima de 2 dígitos"),

    anoExpiracaoCartao: Yup.string()
                    .required("Campo obrigatório")
                    .matches(/^[0-9]+$/, "Apenas números")
                    .min(4, "Quantidade mínima de 4 dígitos")
                    .max(4, "Quantidade máxima de 4 dígitos"),
    
    codigoSeguranca: Yup.string()
                    .required("Campo obrigatório")
                    .matches(/^[0-9]+$/, "Apenas números")
                    .min(3, "Quantidade mínima de 3 dígitos")
                    .max(4, "Quantidade máxima de 4 dígitos"),

    nomeCartao: Yup.string()
                    .required("Campo obrigatório")
                    .min(3, "Nome da pessoa igual escrito no cartão")
                    .test("nome-cartao", "Nome da pessoa igual escrito no cartão", (value) => {
                        let result = true;
                        let nomes = ["visa", "master", "mastercard", "master card", "elo", "inter", "nubank" ,"c6"];
                        
                        if(value !== undefined && nomes.includes(value.toLowerCase()))
                            result = false;

                        return result;
                    }),

    numeroCpf: Yup.string()
                    .required("Campo obrigatório")
                    .matches(/^[0-9]+$/, "Apenas números")
                    .min(11, "Quantidade mínima de 11 dígitos")
                    .max(11, "Quantidade máxima de 11 dígitos")
                    .test("cpf-valido", "CPF inválido",(value) => { return value !== undefined ? isValidCpf(value) : false }),

    parcela: Yup.string()
                    .required("Campo obrigatório")
});

const CartaoForm = withFormik<ICartaoForm, PaymentDataViewModel>({
    
    // Mapeamento das propriedades para os campos
    mapPropsToValues: () => {
        return new PaymentDataViewModel();
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props, submitForm }) => {
        try {
            // Cria o token do cartao mercado pago
            const cardTokenMp = await props.mp.createCardToken({
            	cardNumber: values.numeroCartao,
            	cardholderName: values.nomeCartao.toUpperCase(),
            	cardExpirationMonth: values.mesExpiracaoCartao,
            	cardExpirationYear: values.anoExpiracaoCartao,
            	securityCode: values.codigoSeguranca,
            	identificationType: 'CPF',
            	identificationNumber: values.numeroCpf
            })

            const cardTokenPagSeguro = PagSeguro.encryptCard({
                publicKey: props.publicKeyPagBank,
                holder: values.nomeCartao.toUpperCase(),
                number: values.numeroCartao,
                expMonth: values.mesExpiracaoCartao,
                expYear: values.anoExpiracaoCartao,
                securityCode: values.codigoSeguranca
            });

            let total = props.carrinho.itens.reduce((a, b) => a + b.lote.preco + (props.carrinho.evento.isTaxaOnlineCredito ? 
                ((b.lote.preco * props.carrinho.evento.taxaOnlineCredito) < props.carrinho.evento.tarifaMinimaOnline ? 
                props.carrinho.evento.tarifaMinimaOnline : (b.lote.preco * props.carrinho.evento.taxaOnlineCredito)) : 0), 0);

            let venda = {
                total: total,
                tokenMp: cardTokenMp.id,
                tokenPagSeguro: cardTokenPagSeguro.encryptedCard,
                binCartao: values.numeroCartao.substring(0, 6),
                parcelas: values.parcela,
                bandeira: values.metodoPagamento.id,
                meioPagamento: "Cartão de Crédito",
                evento: {id: props.carrinho.evento.id, conta: props.carrinho.evento.conta},
                itensVenda: props.carrinho.itens,
                chaveComissario: props.carrinho.chaveComissario,
                deviceId: props.deviceId,
                ip: props.ip
            }

            var vendaService = new VendaService();
            let operacaoResponse = await vendaService.cadastrar(venda);

            props.onSubmited(operacaoResponse);
        }
        catch (error: any) {
            console.log(error);
            Swal.fire({
                title: 'Ops',
                text: `${error.data.Message}`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#004399',
            })
        }
    }
})(innerForm);

export default CartaoForm;