import './style.css';
import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import produtosVenda from '../../content/img/produtos-venda.png'
import produtor from '../../content/img/produtor.png'
import logoMeta from '../../content/img/meta-logo.png'
import googleAds from '../../content/img/google-adwords-logo.png'

const LandingPageComponent: React.FC = () => {


  const abrirContato = () => {
    window.open('https://wa.me/553597760956', '_blank');
  }

  const inicializarPixelFacebook = () => {
    ReactPixel.init('1443493849154255');
    ReactPixel.pageView();
  }

  useEffect(() => {
    inicializarPixelFacebook()
  });

  return (<div className='lp'>
    {/* <div className='lp-head-line'>
      <h1>Qualidade e inovação na gestão do seu evento</h1>
    </div> */}
    <div className='lp-content'>
      <div className="p-grid p-m-0 p-p-5">
        <div className="p-col-12 p-sm-6 p-lg-6 p-xl-6 p-mb-6 p-text-center">
          <img src={produtosVenda} alt="produtos para vendas" className='lp-image-section' />
        </div>
        <div className="p-col-12 p-sm-6 p-lg-6 p-xl-6 p-mb-6 p-pt-6">
          <span className='lp-title-section'>CONTROLE TOTAL</span>
          <br />
          <span className='lp-subtitle-section'>SOBRE AS SUAS VENDAS</span>
          <ul className='lp-text-section'>
            <li>Ingressos online e presenciais personalizados</li>
            <li>Fichas personalizadas</li>
            <li>Relatórios personalizados e em tempo real</li>
          </ul>
          <button className="lp-button-contato" onClick={abrirContato}>Fale com nosso time!</button>
        </div>
        <div className="p-col-12 p-sm-6 p-lg-6 p-xl-6 p-mb-4 p-pt-6 p-text-right">
          <span className='lp-title-section'>SEGURANÇA E TECNOLOGIA</span>
          <br />
          <span className='lp-subtitle-section'>SOBRE AS SUAS VENDAS</span>
          <ul className='lp-text-section'>
            <li>Gestão do seu evento em um portal exclusivo</li>
            <li>Validação de ingressos por meio do nosso aplicativo</li>
            <li>Impressão antifraude de ingresso com itens de segurança</li>
          </ul>

        </div>
        <div className="p-col-12 p-sm-6 p-lg-6 p-xl-6 p-mb-4 p-text-center">
          <img src={produtor} alt="painel administrativo" className='lp-image-section' />
        </div>
        <div className="p-col-12 p-mb-4 p-mt-6">
          <div className='p-text-center'>
            <span className='lp-title-section'>INFORMAÇÕES ATUALIZADAS EM TEMPO REAL</span>
            <br />
            <span className='lp-subtitle-section'>AUTONOMIA PARA A SUA TOMADA DE DECISÃO</span>
          </div>
          <div className='p-text-center p-mt-4'>
            <button className="lp-button-contato" onClick={abrirContato}>Fale com nosso time!</button>
          </div>
        </div>
        <div className="p-col-10 p-offset-1 p-mt-4 p-mb-4 lp-ads">
          <div className='p-text-center'>
            <span className='lp-title-section'>EVOLUA NA FORMA DE DIVULGAR SEUS EVENTOS!</span>
            <br />
            <span className='lp-subtitle-section'>IMPLEMENTAÇÃO DO SEU PIXEL LIBERADA</span>
          </div>
          <div className='p-text-center p-mt-4'>
            <img src={logoMeta} alt="logo meta" className='lp-image-ads' />
            <img src={googleAds} alt="logo google ads" className='lp-image-ads' />
          </div>
        </div>
        {/* <div className="p-col-10 p-offset-1 p-mt-4 p-mb-4 p-text-center">
          <iframe src="https://app.pipefy.com/public/form/_mprTaym" width="500" height="750" frameBorder="none"></iframe>
        </div> */}
        
        {/* <div className="p-col-10 p-offset-1 taxas">
          <div className='p-text-center container-title-taxas'>
            <span className='lp-title-section'>VENDA GRATUITA DE INGRESSOS</span>
          </div>
          <div className='p-grid card-taxas p-mt-3'>
            <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
              <div className="p-d-flex p-flex-column p-p-5">
                <div className="label p-mb-2 forma-pagamento">VENDA ONLINE</div>
                <div className="value">R$ 0,00</div>
                <div className="text-info">
                  <span>Você não paga nada para vender seus ingressos</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

    </div>
    <div className='lp-footer'></div>
  </div>);
}

export default LandingPageComponent;