export class Cliente {

    /**
     *
     */
    constructor() {
        this.nome = '';
        this.cpf = '';
        this.email = '';
        this.telefone = '';
    }

    public id: number;
    public nome: string;
    public cpf: string;
    public dataNascimento: Date;
    public sexo: string;
    public email: string;
    public telefone: string;
    public senha: string;
    public dataCadastro: Date;
    public confirmacaoSenha: string;
    public tokenRecaptcha: string;
}