const Sizes = {
    border: {
        radius: {
            xs: '2px',
            sm: '4px',
            md: '8px',
            lg: '16px',
            pill: '500px',
            circular: '50%',
        },
        width: {
            hairline: '1px',
            thin: '2px',
            thick: '4px',
            heavy: '8px',
        },
    },
    opacity: {
        level: {
            semiopaque: 0.8,
            intense: 0.6,
            medium: 0.4,
            light: 0.2,
            semitransparent: 0.1,
        },
    },
    shadow: {
        level0: '0px 0px 3px rgba(0, 0, 0, 0.15)',
        level1: '0px 0px 8px rgba(0, 39, 64, 0.1)',
        level2: '0px 0px 24px rgba(0, 39, 64, 0.1)',
        level3: '0px 0px 32px rgba(0, 39, 64, 0.1)',
        level4: '0px 0px 48px rgba(0, 39, 64, 0.1)',
    },
    spacing: {
        inset: {
            quarck: '4px',
            nano: '8px',
            xs: '16px',
            sm: '24px',
            md: '32px',
            lg: '40px',
        },
        squish: {
            quarck: '4px 8px',
            nano: '8px 16px',
            xnano: '8px 24px',
            xs: '16px 24px',
            sm: '16px 32px',
            md: '16px 40px',
            lg: '16px 48px',
        },
        inline: {
            quarck: '4px',
            nano: '8px',
            xxxs: '16px',
            xxs: '24px',
            xs: '32px',
            sm: '40px',
            md: '48px',
            lg: '64px',
            xl: '80px',
        },
        stack: {
            quarck: '4px',
            nano: '8px',
            xxxs: '16px',
            xxs: '24px',
            xs: '32px',
            sm: '40px',
            md: '48px',
            lg: '56px',
            xl: '64px',
            xxl: '80px',
            xxxl: '120px',
            huge: '160px',
            giant: '200px',
        },
    },
    iconSize: {
        sm: '8px',
        md: '16px',
        lg: '24px',
        xl: '32px',
        menu: '22px',
    },
}
export default Sizes;