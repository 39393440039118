import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Divider } from 'primereact/divider';
import GlobalContext from '../../helpers/GlobalContext';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { useHistory } from 'react-router';
import { Button } from 'primereact/button';
import CartaoForm from './CartaoForm';
import { VendaService } from '../../services/VendaService';
import { OperacaoService } from '../../services/OperacaoService';
import { UtilService } from '../../services/UtilService';
import { Venda } from 'passemix-lib/src/models/Venda';
import Swal from 'sweetalert2'
import { Message } from 'primereact/message';
import mpcartao from '../../content/img/mp-cartao.svg';
import mppix from '../../content/img/mp-pix.svg';
import mppicpay from '../../content/img/mp-picpay.svg';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import Pix from '../../content/img/icones-pagamento/pix.svg'
import Lottie from "lottie-react";
import animationData from '../../content/lotties/meditation.json'
import { Operacao } from 'passemix-lib/src/models/Operacao';
import { ClienteService } from '../../services/ClienteService';
import { ToastContainer, toast } from 'react-toastify';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import IdentificacaoComponent from './IdentificacaoComponent';
import { ItemVenda } from 'passemix-lib/src/models/ItemVenda';
import { Ticket } from 'passemix-lib/src/models/Ticket';

declare var MercadoPago: any;
declare var MP_DEVICE_SESSION_ID: any;

export interface ICheckoutComponent {
}

const CheckoutComponent: React.FC<ICheckoutComponent> = (props) => {

	const history = useHistory();
	const meioPagamentoRef = useRef<null | HTMLDivElement>(null);
	const { carrinho, setCarrinho, logout } = useContext(GlobalContext);
	const [cliente, setCliente] = useState<Cliente>();
	const [meioPagamento, setMeioPagamento] = useState('');
	const [vendaResponse, setVendaResponse] = useState<Venda>();
	const [isGerarPix, setIsGerarPix] = useState(false);
	const [ipCliente, setIpCliente] = useState('');
	const [processando, setProcessando] = useState<boolean>(false);
	const [operacaoResultado, setOperacaoResultado] = useState<Operacao>();
	const [exibeIdentificacao, setExibeIdentificacao] = useState(false);
	const [identificacaoValida, setIdentificacaoValida] = useState(true);

	// Producao
	const bk1 = "APP_USR-1ef7154d-0cf4-40db-9a35-b4b2a4da55b2";
	const bk2 = "APP_USR-f3e90674-aff2-4271-aec1-ba53bb067956";
	const pgbk = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtNNWlJNqYn1nnLjJ6Uq1sQLuEPBJV5I0zJf6NbRA9hktfemMU5OfofF3UP15DeDqT8bLF6LzuXw6fUyPTuY+KbP+h6UDQ5QboEXASlDQz6mZLoLBMB+V3YoWJLdTs5Ils3mb6N8vwAbp0FWIDbW+qzc7ntfAdPuDZSKUpP1JdwvUTCzFKwayO2/A/N1qxM4AOlfTXn9iAUmXnS1qF20yQomyNxFgAHnc5vVievQI7oCYZRY6NoZ/pOSvZvihmYI0g+qXtLlDB+NqgezN4xagJTbxGY1EKc6Egvy9YhiL1ZWXml5MChkvdJElmItb0Nib+D0+iI+rrZQFk7/jmq8NuQIDAQAB";

	// Homologacao
    // const pgbk = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB";
	// const bk1 = "TEST-df6a73ac-6124-47b7-8c74-2a7c547f38e1";
	// const bk2 = "TEST-7a4acf49-47a8-43b9-b6cc-9d895b4474cf";

	// Instancia a lib do MP
	const mp = new MercadoPago((carrinho.evento !== undefined && carrinho.evento.conta === "BK1") ? bk1 : bk2, {
		locale: 'pt-BR',
		advancedFraudPrevention: false,
	});

	const montarTickets = (clienteLogado: Cliente) => {
		let itens = new Array<ItemVenda>()

		carrinho.itens.forEach(element => {
			let item = {
				lote: element.lote,
				ticket: { participante: clienteLogado.nome, documento: clienteLogado.cpf, modelo: '', valido: true } as Ticket
			} as ItemVenda;

			itens.push(item);
		});

		setCarrinho({ ...carrinho, itens: itens })

		let loteModelo = carrinho.itens.filter(x => x.lote.modelo === true)[0];
		
		if(loteModelo !== undefined)
			setExibeIdentificacao(loteModelo.lote.modelo);
	}

	const changeIdentificacao = (identificacoesValida: boolean) => {
		setIdentificacaoValida(identificacoesValida)
	}

	const carregarCliente = async () => {
		try {

			let service = new ClienteService();
			let response = await service.obterCliente();

			setCliente(response);
			montarTickets(response);
		}
		catch (error: any) {

			if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				toast.warn('Falha ao carregar dados');
			}
		}
	}

	const verificarTicketModelo = () => {
		let itens = carrinho.itens.filter(x => x.lote.modelo === true);

		for (let index = 0; index < itens.length; index++) {
			const item = itens[index];
			
			if(item.ticket.modelo === '')
				return false
		}

		return true;
	}

	const selecionaMeioPagamento = (meioPagamento: string) => {
		if (vendaResponse === undefined) {

			if(identificacaoValida && verificarTicketModelo())
			{
				setMeioPagamento(meioPagamento);
			}else {
				toast.warn('Preencha corretamente os dados de identificação');
			}
		}
	}

	const buscarAtualizacaoOperacao = async (operacao: Operacao) => {

		setProcessando(true);
		var operacaoService = new OperacaoService();

		const syncInterval = setInterval(async () => {
			let operacaoResponse = await operacaoService.obter(operacao.agregado);
			if (operacaoResponse !== undefined && operacaoResponse !== null) {
				setOperacaoResultado(operacaoResponse);
				clearInterval(syncInterval);
			}
		}, 5000);

		return () => clearInterval(syncInterval);
	}

	const obterChavePix = async () => {
		try {

			setIsGerarPix(true);
			
			let total = carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlinePix ?
				((b.lote.preco * carrinho.evento.taxaOnlinePix) < carrinho.evento.tarifaMinimaOnline ?
					carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlinePix)) : 0), 0);

			let venda = {
				total: total,
				meioPagamento: "Pix",
				evento: { id: carrinho.evento.id, conta: carrinho.evento.conta },
				itensVenda: carrinho.itens,
				chaveComissario: carrinho.chaveComissario,
				ip: ipCliente
			}

			let vendaService = new VendaService();
			let operacaoResponse = await vendaService.cadastrar(venda);

			buscarAtualizacaoOperacao(operacaoResponse);

		} catch (error: any) {

			if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				Swal.fire({
					title: 'Ops',
					text: `${error.data.Message}`,
					icon: 'error',
					showCancelButton: false,
					confirmButtonColor: '#004399',
				})
			}
		}
	}

	const copiarChavePix = () => {
		if (vendaResponse) {
			let selBox = document.createElement('textarea');
			selBox.style.position = 'fixed';
			selBox.style.left = '0';
			selBox.style.top = '0';
			selBox.style.opacity = '0';
			selBox.value = vendaResponse.chavePix;
			document.body.appendChild(selBox);
			selBox.focus();
			selBox.select();
			document.execCommand('copy');
			document.body.removeChild(selBox);

			Swal.fire({
				title: 'Pronto',
				text: "Cole a chave na área Pix Copia e Cola do app do seu banco.",
				icon: 'info',
				showCancelButton: false,
				confirmButtonColor: '#004399',
			})
		}
	}

	const obterPagamentoPicPay = async () => {
		try {
			setIsGerarPix(true);

			let total = carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlineCredito ?
				((b.lote.preco * carrinho.evento.taxaOnlineCredito) < carrinho.evento.tarifaMinimaOnline ?
					carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlineCredito)) : 0), 0);

			let venda = {
				total: total,
				meioPagamento: "PicPay",
				evento: { id: carrinho.evento.id, conta: carrinho.evento.conta },
				itensVenda: carrinho.itens,
				chaveComissario: carrinho.chaveComissario,
				ip: ipCliente
			}

			let vendaService = new VendaService();
			let operacaoResponse = await vendaService.cadastrar(venda);

			buscarAtualizacaoOperacao(operacaoResponse);

		} catch (error: any) {

			if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				Swal.fire({
					title: 'Ops',
					text: `${error.data.Message}`,
					icon: 'error',
					showCancelButton: false,
					confirmButtonColor: '#004399',
				})
			}
		}
	}

	const pagarPicpay = () => {
		if (vendaResponse) {
			let url = vendaResponse.chavePix
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
			if (newWindow) newWindow.opener = null
		}
	}

	const obterIpCliente = async () => {
		let service = new UtilService();
		let response = await service.obterIp();

		setIpCliente(response.IPv4);
	}

	const validarLimiteCompra = useCallback(async () => {
		try {

			let evento = carrinho.evento;
			var service = new VendaService();
			var response = await service.obterClienteEvento(evento.id);

			if (response !== undefined && response.length >= evento.limiteCompra) {
				Swal.fire({
					title: 'Você já comprou',
					text: "Este evento possui um limite de compras.",
					icon: 'error',
					showCancelButton: false,
					confirmButtonColor: '#004399',
				}).then((isConfirm) => {
					if (isConfirm) {
						history.push(`/${evento.url}`);
					}
				})
			}
		}
		catch (error) {
			console.log(error);
		}
	}, [carrinho.evento, history]);

	const processarResultadoOperacao = useCallback(async () => {
		try {
			if (operacaoResultado !== undefined) {
				setProcessando(false);

				switch (operacaoResultado.nome) {
					case 'sale.payment.paid':
						// history.push(`/confirmacao/${operacaoResultado.referencia}`);
						history.push(`/compra/${operacaoResultado.referencia}`);
						break;
					case 'sale.payment.in_process':
						Swal.fire({
							title: 'Processando pagamento.',
							text: "Em menos de 2 dias úteis informaremos por e-mail se foi creditado ou não.",
							icon: 'warning',
							showCancelButton: false,
							confirmButtonColor: '#004399',
						})
						history.push("/meus-ingressos");
						break;
					case 'sale.payment.refused':
						Swal.fire({
							title: 'Pagamento recusado.',
							text: operacaoResultado.erro,
							icon: 'error',
							showCancelButton: false,
							confirmButtonColor: '#004399',
						})
						// history.push("/meus-ingressos");
						break;
					case 'sale.checkout.cancelled':
						Swal.fire({
							title: 'Pagamento cancelado.',
							text: operacaoResultado.erro,
							icon: 'warning',
							showCancelButton: false,
							confirmButtonColor: '#004399',
						})
						history.push(`/${carrinho.evento.url}`);
						break;
					case 'sale.payment.cancelled':
						Swal.fire({
							title: 'Pagamento cancelado.',
							text: "O prazo para o pagamento esgotou.",
							icon: 'warning',
							showCancelButton: false,
							confirmButtonColor: '#004399',
						})
						history.push(`/${carrinho.evento.url}`);
						break;
					case 'sale.checkout.payment':
						let pagamento = { chavePix: JSON.parse(operacaoResultado.dados).chave, qrCodePix: JSON.parse(operacaoResultado.dados).qrCode } as Venda;
						setVendaResponse(pagamento);
						setIsGerarPix(false);
						buscarAtualizacaoOperacao(operacaoResultado);
						break;
					default:
						console.log(operacaoResultado.nome);
						break;
				}
			}
		}
		catch (error) {
			console.log(error);
		}
	}, [carrinho.evento?.url, history, operacaoResultado]);

	useEffect(() => {
		processarResultadoOperacao();
	}, [processarResultadoOperacao, operacaoResultado])


	useEffect(() => {
		if (!carrinho.evento)
			history.push("/eventos");

		if (carrinho.evento !== undefined && carrinho.evento.limiteCompra !== 0)
			validarLimiteCompra();

		obterIpCliente();
		carregarCliente();

	}, [carrinho.evento, history, validarLimiteCompra])

	return (
		<div className="p-grid p-m-0">
			<ToastContainer position="top-right" autoClose={8000} />
			<div className="p-col-12 p-md-12 p-lg-6 p-xl-6 p-lg-offset-3 p-xl-offset-3">
				<Divider align="left">
					<span className="text-dividers">Finalizar compra</span>
				</Divider>
				<div className="p-grid p-mt-2">
					<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
						<div className="card-checkout">
							<div className="card-checkout-header">
								Comprando como
							</div>
							<div className='card-checkout-body'>
								<div>
									<span className='text-info'>{cliente?.nome}</span>
									<br />
									<span className='text-info'>{cliente?.email}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
						<div className="card-checkout">
							<div className="card-checkout-header">
								Identificação
							</div>
							<div className='card-checkout-body'>
								{
									!exibeIdentificacao ? 
										<div className="p-d-flex p-jc-between pointer" onClick={() => setExibeIdentificacao(true)}>
											<div className='p-d-flex'>
												<div className='p-mr-2 p-pt-1'>
													<i className="pi pi-check-circle" style={{ 'fontSize': '2em', color: '#16a34a' }}></i>
												</div>
												<div>
													<span className='text-title-md'>Serei o titular de todos os ingressos</span>
													<br />
													<span className='text-info'>Clique aqui para editar os dados.</span>
												</div>
											</div>
											<div>
												<i className="pi pi-user-edit" style={{ 'fontSize': '1.5em' }}></i>
											</div>
										</div> : ''
								}
								<div>
									{
										exibeIdentificacao && cliente !== undefined && carrinho.itens !== undefined ?
										<IdentificacaoComponent onChange={changeIdentificacao} /> : ''
									}
								</div>
							</div>
						</div>
					</div>
					<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
						<div className="card-checkout" ref={meioPagamentoRef}>
							<div className="card-checkout-header">
								Pagamento
							</div>
							<div className="card-checkout-body">
								{
									carrinho.evento?.pix ?
										<div className="meio-pagamento" onClick={() => selecionaMeioPagamento("Pix")}>
											<div className="p-d-flex p-jc-between">
												<div>
													<p className='text-meio-pagamento'>Pix</p>
												</div>
												<div className='p-text-center'>
													<img className="icone-mp" src={mppix} alt="Meio de pagamento pix" />
													<br />
													<span className='text-info-sm'>R$ {
														UtilsHelper.formatCurrency(carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlinePix ?
															((b.lote.preco * carrinho.evento.taxaOnlinePix) < carrinho.evento.tarifaMinimaOnline ?
																carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlinePix)) : 0), 0))
													}
													</span>
												</div>
											</div>
											{
												meioPagamento !== '' && meioPagamento === "Pix" ?
													<div>
														{
															vendaResponse === undefined ?
																<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
																	<Button label='Finalizar Compra' onClick={obterChavePix} className="button-comprar p-button-lg p-mt-4" disabled={isGerarPix || !identificacaoValida} loading={isGerarPix} />
																</div>
																:
																<div className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-text-center">
																	<Lottie
																		animationData={animationData}
																		loop={true}
																		autoplay={true}
																		rendererSettings={{
																			preserveAspectRatio: 'xMidYMid'
																		}}
																		style={{ height: '20vh' }}
																	/>
																	<strong>aguardando seu pagamento!</strong>
																	<p><strong>copie o código</strong> abaixo e <strong>cole na área Pix Copia e Cola</strong> do app do seu banco.</p>

																	<img className="img-fluid img-qrcode p-d-none p-d-lg-inline p-d-xl-inline" src={`data:image/png;base64,${vendaResponse.qrCodePix}`} alt="Qr Code Pix" />

																	<div className="col-12 md:col-4">
																		<div className="p-inputgroup">
																			<InputText type="text" name="chavePix" value={vendaResponse.chavePix} disabled={true} />
																			<Button icon="pi pi-copy" className="p-button-outlined p-button-secondary" onClick={copiarChavePix} />
																		</div>
																	</div>

																	<Card className='p-mt-5 p-shadow-5'>
																		<h4 className='p-mb-5 p-mt-0'>como pagar usando Pix</h4>
																		<div className="text-checkout-instrucoes p-text-center p-m-0">
																			<Button icon="pi pi-copy" className="p-button-outlined p-button-secondary" onClick={copiarChavePix} />
																			<p><strong>1. copie</strong> o código gerado</p>

																			<img src={Pix} alt="Pix" style={{ 'width': '20%' }} />
																			<p><strong>2. </strong> encontre o <strong>Pix Copia e Cola</strong> no app do seu banco e cole lá! confira os valores e realize o pagamento.</p>
																			<p><strong>3. </strong> pronto! depois que o pagamento for confirmado, seus ingressos ficaram disponíveis.</p>
																		</div>
																	</Card>
																</div>
														}
													</div>
													: ''
											}
										</div> : ''
								}

								{
									carrinho.evento?.picPay ?
										<div className="meio-pagamento" onClick={() => selecionaMeioPagamento("PicPay")}>
											<div className="p-d-flex p-jc-between">
												<div>
													<p className='text-meio-pagamento'>PicPay</p>
												</div>
												<div>
													<img className="icone-mp" src={mppicpay} alt="Meio de pagamento picpay" />
													<br />
													<span className='text-info-sm'>R$ {
														UtilsHelper.formatCurrency(carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlineCredito ?
															((b.lote.preco * carrinho.evento.taxaOnlineCredito) < carrinho.evento.tarifaMinimaOnline ?
																carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlineCredito)) : 0), 0))
													}
													</span>
												</div>
											</div>
											{
												meioPagamento !== '' && meioPagamento === "PicPay" ?
													<div>
														{
															vendaResponse === undefined ?
																<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
																	<Button label='Finalizar Compra' onClick={obterPagamentoPicPay} className="button-comprar p-button-lg p-mt-4" disabled={isGerarPix || !identificacaoValida} loading={isGerarPix} />
																</div>
																:
																<div className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-text-center">
																	<Lottie
																		animationData={animationData}
																		loop={true}
																		autoplay={true}
																		rendererSettings={{
																			preserveAspectRatio: 'xMidYMid'
																		}}
																		style={{ height: '20vh' }}
																	/>
																	<strong>aguardando seu pagamento!</strong>
																	<p>clique em <strong>Pagar no App</strong> ou pague com o QR code.</p>

																	<img className="img-fluid img-qrcode" src={`${vendaResponse.qrCodePix}`} alt="Qr Code" />

																	<Button className='button-comprar p-button-lg' label="Pagar no App" onClick={pagarPicpay} />

																	<Card className='p-mt-5 p-shadow-5'>
																		<h4 className='p-mb-5 p-mt-0'>como pagar usando PicPay</h4>
																		<div className="text-checkout-instrucoes p-text-center p-m-0">
																			<p><strong>1. </strong>clique em <strong>Pagar no App</strong> para pagar no app do PicPay ou pague com o QR code no app do PicPay.</p>
																			<p><strong>3. </strong> pronto! depois que o pagamento for confirmado, seus ingressos ficaram disponíveis.</p>
																		</div>
																	</Card>
																</div>
														}
													</div>
													: ''
											}
										</div> : ''
								}

								{
									carrinho.evento?.cartao ?
										<div className="meio-pagamento" onClick={() => selecionaMeioPagamento("Cartão de Crédito")}>
											<div className="p-d-flex p-jc-between">
												<div>
													<p className='text-meio-pagamento'>Cartão de crédito</p>
												</div>
												<div>
													<img className="icone-mp" src={mpcartao} alt="Meio de pagamento cartão" />
													<br />
													<span className='text-info-sm'>R$ {
														UtilsHelper.formatCurrency(carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlineCredito ?
															((b.lote.preco * carrinho.evento.taxaOnlineCredito) < carrinho.evento.tarifaMinimaOnline ?
																carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlineCredito)) : 0), 0))
													}
													</span>
												</div>
											</div>
											{
												meioPagamento !== '' && meioPagamento === "Cartão de Crédito" ?
													<CartaoForm
														mp={mp}
														publicKeyPagBank={pgbk}
														deviceId={MP_DEVICE_SESSION_ID}
														carrinho={carrinho}
														ip={ipCliente}
														processando={processando}
														identificacaoValida={identificacaoValida}
														onSubmited={buscarAtualizacaoOperacao}
													/> : ''
											}
										</div> : ''
								}
							</div>
						</div>
					</div>
					<div className="p-col-12 p-md-12 p-lg-12">
						{
							carrinho.evento ?
								<div className="card-checkout p-m-b-4">
									<div className="card-checkout-header">
										Resumo da Compra
									</div>
									<div className="card-checkout-body p-pt-3">
										<div className='p-mb-2'>
											<div className="text-label-info">Evento</div>
											<div className="text-info-resumo-compra">{carrinho.evento.nome}</div>
										</div>
										<Divider />
										<div className="text-label-info p-mt-2 p-mb-2">Ingressos</div>
										{
											carrinho.itens.map((item, i) =>
												<div className="p-d-flex p-jc-between" key={i}>
													<div className="p-mb-2 p-mr-2">
														<span className="text-info-resumo-compra">{item.lote.nome}</span> <small>({item.lote.tipoIngresso})</small>
													</div>
													<div className="p-mb-2 p-mr-2">
														{
															meioPagamento === 'Pix' ?
																<span className="text-info-resumo-compra">R${UtilsHelper.formatCurrency(item.lote.preco)}</span>
																:
																<span className="text-info-resumo-compra">R${UtilsHelper.formatCurrency(item.lote.preco)}</span>
														}
													</div>
												</div>
											)
										}
										<Divider />
										<div className="p-col-12 p-sm-12 p-lg-12 p-xl-12">
											<div className="p-d-flex p-jc-between text-info">
												<div>
													<strong>Taxa de conveniência:</strong>
												</div>
												<div>
													{
														meioPagamento === 'Pix' ?
															<strong>R$ {
																UtilsHelper.formatCurrency((carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlinePix ?
																	((b.lote.preco * carrinho.evento.taxaOnlinePix) < carrinho.evento.tarifaMinimaOnline ?
																		carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlinePix)) : 0), 0)) -
																	(carrinho.itens.reduce((a, b) => a + b.lote.preco, 0)))
															}
															</strong>
															:
															<strong>R$ {
																UtilsHelper.formatCurrency((carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlineCredito ?
																	((b.lote.preco * carrinho.evento.taxaOnlineCredito) < carrinho.evento.tarifaMinimaOnline ?
																		carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlineCredito)) : 0), 0)) -
																	(carrinho.itens.reduce((a, b) => a + b.lote.preco, 0)))
															}
															</strong>
													}
												</div>
											</div>
										</div>
										<Divider />
										<div className="p-col-12 p-sm-12 p-lg-12 p-xl-12">
											<div className="p-d-flex p-jc-between">
												<div>
													<strong>Total</strong>
												</div>
												<div>
													{
														meioPagamento === 'Pix' ?
															<strong>R$ {
																UtilsHelper.formatCurrency(carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlinePix ?
																	((b.lote.preco * carrinho.evento.taxaOnlinePix) < carrinho.evento.tarifaMinimaOnline ?
																		carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlinePix)) : 0), 0))
															}
															</strong>
															:
															<strong>R$ {
																UtilsHelper.formatCurrency(carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlineCredito ?
																	((b.lote.preco * carrinho.evento.taxaOnlineCredito) < carrinho.evento.tarifaMinimaOnline ?
																		carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlineCredito)) : 0), 0))
															}
															</strong>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								: ''
						}
					</div>
					<div className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-text-center">
						<Message severity="warn" text="Ao finalizar a compra, você afirma está ciênte dos termos de uso." />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CheckoutComponent;