import { ResponseHelper } from "../helpers/ResponseHelper";
import { Evento } from "passemix-lib/src/models/Evento";
import api from "./Api";

export class EventoService {
    
    private endpoint = 'v1/eventos';

    public async obterEventosLoja(): Promise<Evento[]> {
        return await api.get<Evento[]>(`${this.endpoint}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterEventosClienteComissario(): Promise<Evento[]> {
        return await api.get<Evento[]>(`${this.endpoint}/cliente-comissario`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorId(id: number): Promise<Evento> {
        return await api.get<Evento>(`${this.endpoint}/${id}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorUrl(url: string): Promise<Evento> {
        return await api.get<Evento>(`${this.endpoint}/url/${url}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}