import axios from "axios";
import { GlobalService } from "./GlobalService";

const getBaseUrl = (): string => {
    
    if(process.env.REACT_APP_API_URL !== undefined)
        return process.env.REACT_APP_API_URL?.toString();


    // return "http://localhost:5000";
    return "https://api-loja.passemix.com.br";
}

const api = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${GlobalService.getSessao().accessToken}`
            }
        }
    }
})

export default api;