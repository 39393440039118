import api from "./Api";
import { Login } from "passemix-lib/src/models/Login";
import { LoginResponse } from "passemix-lib/src/models/LoginResponse";
import { RecuperacaoSenhaViewModel } from "passemix-lib/src/viewmodels/RecuperacaoSenhaViewModel";
import { ResponseHelper } from "../helpers/ResponseHelper";

export class UsuarioService {

    private endpoint = 'v1/usuarios';

    public async autenticar(item: Login): Promise<LoginResponse> {
        return await api.post<LoginResponse>(`${this.endpoint}/token`, item)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async loginFacebook(item: any): Promise<LoginResponse> {
        return await api.post<LoginResponse>(`${this.endpoint}/login-facebook`, item)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async recuperarSenha(recuperacaoSenha: RecuperacaoSenhaViewModel) {
        return await api.post(`${this.endpoint}/recupera_senha`, recuperacaoSenha)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async alterarSenha(model: any): Promise<any> {
        return await api.post<any>(`${this.endpoint}/alera_senha`, model)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}