import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-toastify/dist/ReactToastify.css';

import './content/css/prime.css';
import './content/css/global.css';
import './content/css/text.css';

import ProvideContext from './helpers/ProvideContext';

import ScrollToTop from './helpers/ScrollToTop';
import HomeContainer from './containers/HomeContainer';
import EventoContainer from './containers/EventoContainer';
import ContatoContainer from './containers/ContatoContainer';
import TermosContainer from './containers/TermosContainer';
import LoginContainer from './containers/LoginContainer';
import PrivateRoute from './helpers/PrivateRoute';
import ContaContainer from './containers/ContaContainer';
import VendaContainer from './containers/VendaContainer';
import CheckoutContainer from './containers/CheckoutContainer';
import ConfirmacaoContainer from './containers/ConfirmacaoContainer';
import EnderecoContainer from './containers/EnderecoContainer';
import ContaConfirmacaoContainer from './containers/ContaConfirmacaoContainer';
import LandingPageContainer from './containers/LandingPageContainer';
import VendasContainer from './containers/VendasContainer';
import ComissarioContainer from './containers/ComissarioContainer';
import VendaComissarioContainer from './containers/VendaComissarioContainer';
import EventosContainer from './containers/EventosContainer';

function App() {
  	
  	useEffect(() => {
		document.title = "PasseMix - Plataforma de ingressos";
	}, []);

	return (
		<ProvideContext>
			<Router>
				<ScrollToTop />
				<Switch>
					<Route exact path="/" component={HomeContainer} />
					<Route exact path="/contato" component={ContatoContainer} />
					<Route exact path="/termos" component={TermosContainer} />
					<Route exact path="/login" component={LoginContainer} />
					<Route exact path="/produtor/lp" component={LandingPageContainer} />
					<PrivateRoute exact path="/minha-conta" component={ContaContainer} />
					<PrivateRoute exact path="/meus-ingressos" component={VendasContainer} />
					<PrivateRoute exact path="/area-comissario" component={ComissarioContainer} />
					<PrivateRoute exact path="/area-comissario/venda" component={VendaComissarioContainer} />
					<PrivateRoute exact path="/conta/confirmacao" component={ContaConfirmacaoContainer} />
					<PrivateRoute path="/conta/endereco" component={EnderecoContainer} />
					<PrivateRoute path="/compra/:idVenda" component={VendaContainer} />
					<PrivateRoute exact path="/checkout" component={CheckoutContainer} />
					<PrivateRoute path="/confirmacao/:idVenda" component={ConfirmacaoContainer} />
					<Route exact path="/evento/:nomeEvento" component={EventoContainer} />
					<Route exact path="/eventos" component={EventosContainer} />
					<Route exact path="/:nomeEvento" component={EventoContainer} />
					<Redirect path="*" to="/" />
				</Switch>
			</Router>
		</ProvideContext>
	);
}

export default App;
