import { Cliente } from "passemix-lib/src/models/Cliente";
import { Evento } from "passemix-lib/src/models/Evento";
import { ItemVenda } from "passemix-lib/src/models/ItemVenda";

export class Carrinho {

    public cliente: Cliente;
    public evento: Evento;
    public itens: ItemVenda[];
    public chaveComissario: string;
    public erroParticipante: boolean;
}