import React, { useCallback, useEffect, useState } from 'react';
import { Venda } from 'passemix-lib/src/models/Venda';
import { VendaService } from '../../services/VendaService';
import { Divider } from 'primereact/divider';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import ConfirmacaoForm from './ConfirmacaoForm';
import { ToastContainer, toast } from 'react-toastify';

export interface IConfirmacaoComponent {
	idVenda: string;
}

const ConfirmacaoComponent: React.FC<IConfirmacaoComponent> = (props) => {

	const history = useHistory();
	const [venda, setVenda] = useState<Venda>();
	
	const carregarVenda = useCallback(async () => {
		try {

			let service = new VendaService();
			let response = await service.obterPorId(parseInt(props.idVenda));

			setVenda(response);
		}
		catch (error) {
			toast.error("Erro ao carregar compra");
		}

	}, [props.idVenda]);

	const onSave = (venda: Venda) => {

		let entrega = venda.itensVenda.filter(x => x.lote.entrega === true);

		// Se existir lote que faz entrega redireciona para preenhcer o endereco
		if(entrega.length > 0)
		{
			history.push(`/conta/endereco`)
		}else{
			history.push(`/compra/${venda.id}`)
		}
	}

	useEffect(() => {
		carregarVenda();
	}, [carregarVenda])

	
	return (
		<div className="p-grid p-m-0">
			<ToastContainer position="top-right" autoClose={8000} />
			<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
				<h2>Confirmação</h2>
				<Divider />
				<p><FontAwesomeIcon icon={faCheckCircle} size="2x" className="p-mr-1" style={{color: "#28a745"}} /> O seu pagamento foi aprovado!</p>
				<p>Agora precisamos que identifique os participantes com o nome e documento de cada um.</p>
				{
					venda ? 
					<ConfirmacaoForm venda={venda} onSave={onSave} /> : ''
				}
			</div>
			<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
				<Divider align="left" >
					<b>Informações Importantes</b>
				</Divider>
				<div className="text-checkout-instrucoes">
					<ul>
						<li>É indispesável a apresentação do voucher com documento;</li>
						<li>Em caso de cópias do voucher, o sistema de segurança validará apenas o primeiro acesso;</li>
						<li>Após validado uma vez, o voucher não poderá ser reutilizado;</li>
						<li>Em caso de meia entrada indispensável a apresentação de documentos que lhe da o direito;</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default ConfirmacaoComponent;