import React from 'react';
import ContatoComponent from '../components/Contato/ContatoComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const ContatoContainer: React.FC = () => {
	
	const conteudo = 
	<React.Fragment>
		<ContatoComponent />
	</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default ContatoContainer;