import * as Yup from "yup";
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { UtilsHelper } from "passemix-lib/src/helpers/UtilsHelper";
import { isValid as isValidCpf } from "@fnando/cpf";

export interface ILoginForm 
{
    onCheck: any;
}

const innerForm = (props: ILoginForm & FormikProps<{email: string}>) => 
{
    const { values } = props;

    return (
        <Form>
            <div className="p-fluid p-grid p-text-left">
                <div className="p-col-12">
                    <div className="label">E-mail ou CPF</div>
                    <InputText className="input-login" type="text" name="email" value={values.email} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="email" /></small>
                </div>
                <div className="p-col-12 p-pt-2">
                    <Button type="submit" label="Entrar" className='p-button-primary' disabled={ props.isSubmitting } loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    email: Yup.string().required("Campo obrigatório")
    .test('cpf-valido', 'CPF inválido', (value) => {
        if(value !== undefined && !value?.includes('@'))
        {
            return isValidCpf(value);
        }

        return true;
    })
});


const LoginForm = withFormik<ILoginForm, {email: string}>({
    
    // Mapeamento das propriedades para os campos
    mapPropsToValues: () => 
    {
        return {email: ''};
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props }) => 
    {
        if(values.email.includes('@')){
            await props.onCheck(values.email);
        }else{
            let cpfFormatado = UtilsHelper.formatCpf(values.email);
            await props.onCheck(cpfFormatado);
        }
    }    
})(innerForm);

export default LoginForm;