import { ResponseHelper } from "../helpers/ResponseHelper";
import { Operacao } from "passemix-lib/src/models/Operacao";
import { Venda } from "passemix-lib/src/models/Venda";
import api from "./Api";

export class VendaService {
    
    private endpoint = 'v1/vendas';

    public async obterVendasCliente(): Promise<Venda[]> {
        return await api.get<Venda[]>(`${this.endpoint}/cliente`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorId(id: number): Promise<Venda> {
        return await api.get<Venda>(`${this.endpoint}/${id}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterClienteEvento(idEvento: number): Promise<Venda[]> {
        return await api.get<Venda[]>(`${this.endpoint}/evento/${idEvento}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterExtratoComissario(): Promise<any[]> {
        return await api.get<any[]>(`${this.endpoint}/extrato_comissario`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrar(item: any): Promise<Operacao> {
        return await api.post<Operacao>(`${this.endpoint}`, item)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrarVendaDigital(item: any): Promise<Venda> {
        return await api.post<Venda>(`${this.endpoint}/digital`, item)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrarVendaConvite(item: any): Promise<Operacao> {
        return await api.post<Operacao>(`${this.endpoint}/convite`, item)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async confirmar(item: Venda): Promise<Venda> {
        return await api.post<Venda>(`${this.endpoint}/confirmacao`, item)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async downloadPdf(idVenda: number): Promise<any> {
        return await api.get<any>(`${this.endpoint}/download/${idVenda}`, { responseType: 'blob' })
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}