import React from 'react';
import * as Yup from "yup"
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';

export interface ITrocaSenhaForm {
    onSave: any;
    erro: string;
}

const innerForm = (props: ITrocaSenhaForm & FormikProps<{ senhaAtual: string, senhaNova: string, confirmacaoSenha: string }>) => {

    const { values } = props;

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2">A sua senha deve conter:</p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li>No mínimo 8 caracteres </li>
                <li>Pelo menos uma letra maiúscula</li>
                <li>Pelo menos uma letra minúscula</li>
                <li>Pelo menos um número</li>
            </ul>
        </React.Fragment>
    );

    return (
        <Form>
            <div className="p-grid p-fluid ">
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                            <div className="label">Senha atual</div>
                            <Password
                                name="senhaAtual"
                                value={values.senhaAtual}
                                onChange={props.handleChange}
                                promptLabel="Digite sua senha"
                                weakLabel='Senha com segurança fraca'
                                mediumLabel='Senha com segurança mediana'
                                strongLabel='Senha segura'
                                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                                toggleMask
                                feedback={false}
                            />
                            <small className="invalid"><ErrorMessage name="senhaAtual" /></small>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                            <div className="label">Nova senha</div>
                            <Password
                                name="senhaNova"
                                value={values.senhaNova}
                                onChange={props.handleChange}
                                promptLabel="Digite sua senha"
                                weakLabel='Senha com segurança fraca'
                                mediumLabel='Senha com segurança mediana'
                                strongLabel='Senha segura'
                                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                                toggleMask
                                feedback={true}
                                footer={footer}
                            />
                            <small className="invalid"><ErrorMessage name="senhaNova" /></small>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                            <div className="label">Confirmar nova senha</div>
                            <Password
                                name="confirmacaoSenha"
                                value={values.confirmacaoSenha}
                                onChange={props.handleChange}
                                promptLabel="Confirme sua senha"
                                weakLabel='Senha com segurança fraca'
                                mediumLabel='Senha com segurança mediana'
                                strongLabel='Senha segura'
                                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                                toggleMask
                                feedback={true}
                                footer={footer}
                            />
                            <small className="invalid"><ErrorMessage name="confirmacaoSenha" /></small>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-8 p-xl-8">
                            <small className="invalid">{props.erro}</small>
                        </div>
                        <div className="p-col-12 p-md-12 p-xl-4 p-lg-4 p-mt-3">
                            <Button type="submit" label="Alterar" className="p-button-primary" disabled={props.isSubmitting} loading={props.isSubmitting} />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    senhaAtual: Yup.string().required("Campo obrigatório"),
    senhaNova: Yup.string()
        .required("Campo obrigatório")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Informe uma senha forte'),
    confirmacaoSenha: Yup.string()
        .required("Campo obrigatório")
        .oneOf([Yup.ref('senhaNova'), null], "A confirmação deve ser igual a nova senha.")
});

const TrocaSenhaForm = withFormik<ITrocaSenhaForm, { senhaAtual: string, senhaNova: string, confirmacaoSenha: string }>({

    mapPropsToValues: () => {

        return { senhaAtual: '', senhaNova: '', confirmacaoSenha: '' };
    },

    validationSchema: validationForm,
    validateOnChange: false,

    handleSubmit: async (values, { props, resetForm }) => {
        try {
            props.onSave(values);
            resetForm();
        }
        catch (error) {
            console.log(error)
        }
    }
})(innerForm);

export default TrocaSenhaForm;