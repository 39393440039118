import { Endereco } from 'passemix-lib/src/models/Endereco';
import { EnderecoService } from '../../services/EnderecoService';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EnderecoForm from './EnderecoForm';

export interface IEnderecoComponent {
}

const EnderecoComponent: React.FC<IEnderecoComponent> = (props) => {

	const history = useHistory();
	const [endereco, setEndereco] = useState<Endereco>(new Endereco());

	useEffect(() => { carregaEndereco(); })

	const carregaEndereco = async () => {
		try {
			let service = new EnderecoService();
			let response = await service.obter();

			setEndereco(response);
		}
		catch (error) {
			console.log(error);
		}
	}

	const onSave = () => {
		history.push(`/conta`)
	}

	return (
		<div className="p-grid p-m-0">
			<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
				<h2>Endereço</h2>
				<Divider />
				<p>Cadastre aqui o endereço de entrega.</p>
				<EnderecoForm endereco={endereco} onSave={onSave} />
			</div>
		</div>
	);
}

export default EnderecoComponent;