import api from "./Api";
import { Endereco } from "passemix-lib/src/models/Endereco";
import { ResponseHelper } from "../helpers/ResponseHelper";
import { CepViewModel } from "passemix-lib/src/viewmodels/CepViewModel";

export class EnderecoService {

    private endpoint = 'v1/enderecos';

    public async consultarCep(cep: string): Promise<CepViewModel> {
        return await api.get<string>(`${this.endpoint}/consulta_cep/${cep}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obter(): Promise<Endereco> {
        return await api.get<Endereco>(`${this.endpoint}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrar(endereco: Endereco): Promise<Endereco> {
        return await api.post<Endereco>(`${this.endpoint}`, endereco)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async atualizar(endereco: Endereco): Promise<Endereco> {
        return await api.put<Endereco>(`${this.endpoint}`, endereco)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}