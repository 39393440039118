import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ComissarioService } from '../../services/ComissarioService';
import { Comissario } from 'passemix-lib/src/models/Comissario';
import GlobalContext from '../../helpers/GlobalContext';
import { ToastContainer, toast } from 'react-toastify';
import Icon from '../../StyleGuide/Components/Icon';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { useHistory } from 'react-router-dom';
import { LoteService } from '../../services/LoteService';
import { Lote } from 'passemix-lib/src/models/Lote';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { InputText } from 'primereact/inputtext';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import ClienteVendaForm from './ClienteVendaForm';
import { ClienteService } from '../../services/ClienteService';
import { GlobalService } from '../../services/GlobalService';
import { ItemVenda } from 'passemix-lib/src/models/ItemVenda';
import { VendaService } from '../../services/VendaService';
import animationData from '../../content/lotties/success-circle.json'
import Swal from 'sweetalert2';
import Lottie from 'lottie-react';
import { Carrinho } from '../../models/Carrinho';

export enum EnumVendaComissarioAcoes {
  Lotes,
  Comprador,
  Confirmacao,
  Sucesso
}

export interface IVendaComissarioComponent {

}

const VendaComissarioComponent: React.FC<IVendaComissarioComponent> = (props) => {

  const history = useHistory();
  const { carrinho, setCarrinho, evento, logout } = useContext(GlobalContext);
  const [lotes, setLotes] = useState<Lote[]>(new Array<Lote>());
  const [comissario, setComissario] = useState<Comissario>();
  const [itensCarrinho, setItensCarrinho] = useState<ItemVenda[]>(new Array<ItemVenda>());
  const [cliente, setCliente] = useState<Cliente>();
  const [cpfCliente, setCpfCliente] = useState<string>();
  const [acaoAtual, setAcaoAtual] = useState(EnumVendaComissarioAcoes.Lotes);
  const [loading, setLoading] = useState(false);

  const carregaLotesComissario = useCallback(async (comissario: Comissario) => {
    try {
      if (comissario !== undefined) {
        let loteService = new LoteService();
        let lotesResponse = await loteService.obterPorEventoComissario(evento.id, comissario.chave);

        if (lotesResponse !== undefined)
          setLotes(lotesResponse);
      }
    } catch (error) {
      console.log(error);
    }
  }, [evento.id])

  const carregaComissario = useCallback(async () => {
    try {
      if (evento.id === undefined)
        history.push("/area-comissario");

      let service = new ComissarioService();
      let response = await service.obterPorEventoCliente(evento.id);

      setComissario(response);
      carregaLotesComissario(response);
    }
    catch (error: any) {
      if (error.status === 401) {
        logout();
        history.push("/login");
      } else {
        toast.warn('Falha ao carregar dados');
      }
    }
  }, [evento.id, history, logout, carregaLotesComissario])

  useEffect(() => {
    carregaComissario();
  }, [carregaComissario])

  const copiarLink = () => {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `https://passemix.com.br/${evento.url}?chave=${comissario?.chave}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    toast.success('Link copiado!');
  }

  const adicionaIngresso = (lote: Lote) => {

    let limiteIngresso = (evento.limiteIngresso !== 0) ? evento.limiteIngresso : 5;

    if (itensCarrinho.length < limiteIngresso) {
      let itensExistente: ItemVenda[] | undefined;

			itensExistente = itensCarrinho.filter(x => x.lote.id === lote.id);

			if (lote.quantidade >= itensExistente.length + 1)
			{
				let itemVenda = new ItemVenda();
				itemVenda.lote = lote;
				setItensCarrinho([...itensCarrinho, itemVenda]);
			}
			else
			{
				toast.info(`Resta apenas ${lote.quantidade} ingressos`);
			}
    }
    else {
      toast.warn(`Limite de ${limiteIngresso} ingressos atingido`);
    }
  }

  const removeIngresso = (lote: Lote) => {
    if (itensCarrinho.length > 0) {
      let index = itensCarrinho.findIndex(x => x.id === lote.id);
      itensCarrinho.splice(index, 1);
      setItensCarrinho([...itensCarrinho]);
    }
  }

  const vender = () => {
    if (itensCarrinho.length === 0) {
      toast.info("Nenhum ingresso foi selecionado")
    } else {

      var carrinho = new Carrinho();
      carrinho.evento = evento;
      // carrinho.lotes = lotesCarrinho;
      carrinho.itens = itensCarrinho;
      carrinho.chaveComissario = comissario!.chave;

      setCarrinho(carrinho);

      let logado = GlobalService.isAutenticado();
      !logado ? history.push("/login") : setAcaoAtual(EnumVendaComissarioAcoes.Comprador);
    }
  }

  const onChangeCpf = (event: any) => {
    const { value } = event.target;
    let cpfCnpjFormatado = UtilsHelper.formatCpf(value);
    event.target.value = cpfCnpjFormatado;
    setCpfCliente(cpfCnpjFormatado);
  }

  const buscarCliente = async () => {
    try {
      if (cpfCliente !== undefined) {
        let service = new ClienteService();
        let response = await service.buscarPorCpf(cpfCliente);

        if (response !== undefined) {
          setCliente(response);
        }
        else {
          let clienteNovo = new Cliente();
          clienteNovo.cpf = cpfCliente;
          setCliente(clienteNovo);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onSaveCadastroCliente = (clienteCadastrado: Cliente) => {
    setCliente(clienteCadastrado);
    setAcaoAtual(EnumVendaComissarioAcoes.Confirmacao);
  }

  const onConfirmCliente = (clienteSelecionado: Cliente) => {
    setCliente(clienteSelecionado);
    setAcaoAtual(EnumVendaComissarioAcoes.Confirmacao);
  }

  const enviarVenda = async () => {
    try 
    {
      setLoading(true);

      let total = carrinho.itens.reduce((a, b) => a + b.lote.preco + (carrinho.evento.isTaxaOnlinePix ?
        ((b.lote.preco * carrinho.evento.taxaOnlinePix) < carrinho.evento.tarifaMinimaOnline ?
          carrinho.evento.tarifaMinimaOnline : (b.lote.preco * carrinho.evento.taxaOnlinePix)) : 0), 0);

      let venda = {
        total: total,
        meioPagamento: "Dinheiro",
        evento: { id: carrinho.evento.id, conta: carrinho.evento.conta },
        itensVenda: carrinho.itens,
        chaveComissario: carrinho.chaveComissario,
        cliente: cliente
      }

      let vendaService = new VendaService();
      let vendaReponse = await vendaService.cadastrarVendaDigital(venda);
      
      if(vendaReponse !== undefined)
        setAcaoAtual(EnumVendaComissarioAcoes.Sucesso)
    } catch (error: any) {
      if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				Swal.fire({
					title: 'Ops',
					text: `${error.data.Message}`,
					icon: 'error',
					showCancelButton: false,
					confirmButtonColor: '#004399',
				})
        history.push("/area-comissario");
			}
    }finally{
      setLoading(false);
    }
  }

  const viewLotes = () => {
    return (
      <React.Fragment>
        {
          (lotes.length > 0) ?
            <>
              <div className="p-d-flex p-ml-2 p-mt-3 p-mb-2">
                <div className='p-pt-1 p-pr-2'>
                  <Icon size='menu' name='ticketOutline' color='primary' />
                </div>
                <div className='text-title'>
                  Lotes
                </div>
              </div>
              <div className="container-ingressos">
                {
                  lotes.map((lote, i) =>
                    <div className="p-grid p-m-1 lote-section" key={i}>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8">
                        <strong>{lote.setor}</strong>
                        <br />
                        <span className="text-lote">{lote.nome} - {lote.tipoIngresso}</span>
                        <br />
                        <span className='text-preco-lote'>R$ {UtilsHelper.formatCurrency(lote.preco)}</span>
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 p-text-left">
                        <div className="p-d-flex p-jc-end">
                          <div className='p-pt-3'>
                            <Button icon="pi pi-minus" className="p-button-rounded p-button-text p-button-outlined button-lote" onClick={() => removeIngresso(lote)} />
                          </div>
                          <div className='qtd-ingressos-selecionado'>
                            <span className="text-qtd-lote">{itensCarrinho.filter(x => x.lote.id === lote.id).length}</span></div>
                          <div className='p-pt-3'>
                            <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-outlined button-lote" onClick={() => adicionaIngresso(lote)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="p-grid p-m-1 text-md">
                  {
                    itensCarrinho.length > 0 ?
                      <div className="p-col-12 p-md-5 p-lg-8 p-xl-8">
                        <div className="">Total</div>
                        <div className="text-valor-total">
                          R$ {UtilsHelper.formatCurrency(itensCarrinho.reduce((a, b) => a + b.lote.preco, 0))}
                        </div>
                      </div> : ''
                  }
                  <div className="p-col">
                    <Button label="VENDER INGRESSOS" className="button-comprar" onClick={() => vender()} />
                  </div>
                </div>
              </div>
            </>
            : ''
        }
        <Button label="Voltar" className="p-button-sm  p-button-outlined p-button-secondary p-mb-2 p-mt-3" onClick={() => history.push("/area-comissario")} />
      </React.Fragment>
    );
  }

  const viewComprador = () => {
    return (
      <React.Fragment>
        <Card className="p-shadow-5">
          <span className='text-title-event'>Informações do comprador</span>
          <br />
          <p className='text-infos-order'>Consulte o cadastro do comprador na plataforma PasseMix, caso não possua será necessário realizar um cadastro básico.</p>
          <div className="p-formgroup-inline">
            <div className="p-field">
              <InputText type="tel" name="cpf" value={cpfCliente} onChange={onChangeCpf} placeholder="CPF" />
            </div>
            <Button type="button" label="Buscar" className='p-button-primary' onClick={buscarCliente} />
          </div>
          {
            cliente !== undefined ? <ClienteVendaForm cliente={cliente} onSave={onSaveCadastroCliente} onConfirm={onConfirmCliente} /> : ''
          }
        </Card>
        <Button label="Voltar etapa" className="p-button-sm  p-button-outlined p-button-secondary p-mb-2 p-mt-3" onClick={() => setAcaoAtual(EnumVendaComissarioAcoes.Lotes)} />
      </React.Fragment>
    );
  }

  const viewConfirmacao = () => {
    return (
      <React.Fragment>
        <span className='text-title-event'>Confirmação</span>
        <br />
        <p className='text-infos-order'>Confirme os ingresso e os dados do comprador para realizar a venda.</p>
        <div className="card-checkout p-m-b-4">
          <div className="card-checkout-body p-pt-3">
            <div className='p-mb-2'>
              <div className="text-label-info">Evento</div>
              <div className="text-info-resumo-compra">{carrinho.evento.nome}</div>
            </div>
            <Divider />
            <div className="text-label-info p-mt-2 p-mb-2">Ingressos</div>
            {
              carrinho.itens.map((item, i) =>
                <div className="p-d-flex p-jc-between" key={i}>
                  <div className="p-mb-2 p-mr-2">
                    <span className="text-info-resumo-compra">{item.lote.nome}</span> <small>({item.lote.tipoIngresso})</small>
                  </div>
                  <div className="p-mb-2 p-mr-2">
                    <span className="text-info-resumo-compra">R$ {UtilsHelper.formatCurrency(item.lote.preco)}</span>
                  </div>
                </div>
              )
            }
            <Divider />
            <div className="p-col-12 p-sm-12 p-lg-12 p-xl-12">
              <div className="p-d-flex p-jc-between">
                <div>
                  <strong>Total</strong>
                </div>
                <div>
                  <strong>R$ {UtilsHelper.formatCurrency(carrinho.itens.reduce((a, b) => a + b.lote.preco, 0))}
                  </strong>
                </div>
              </div>
            </div>
            <Divider />
            <div className="p-col-12 p-sm-12 p-lg-12 p-xl-12">
              <div className="p-grid">
                <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6">
                  <div className="text-label-info">Comprador</div>
                  <div className="text-info-resumo-compra">{cliente!.nome}</div>
                </div>
                <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6">
                  <div className="text-label-info">CPF</div>
                  <div className="text-info-resumo-compra">{cliente!.cpf}</div>
                </div>
                <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6">
                  <div className="text-label-info">Celular</div>
                  <div className="text-info-resumo-compra">{cliente!.telefone}</div>
                </div>
                <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6">
                  <div className="text-label-info">E-mail</div>
                  <div className="text-info-resumo-compra">{cliente!.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p-m-2 p-text-right'>
          <Button type="button" label="Finalizar Venda" className='p-button-primary' onClick={enviarVenda} disabled={loading} />
        </div>
      </React.Fragment>
    );
  }

  const viewSucesso = () => {
    return (<React.Fragment>
      <Card className="p-shadow-5">
        <Lottie
          animationData={animationData}
          loop={false}
          autoplay={true}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid'
          }}
          style={{ height: '20vh' }}
        />
        <div className='p-text-center'>
          <h3>Venda realizada com sucesso, os ingressos estão disponíveis na conta do comprador.</h3>
        </div>
      </Card>
      <Button label="Voltar" className="p-button-sm  p-button-outlined p-button-secondary p-mb-2 p-mt-3" onClick={() => history.push("/area-comissario")} />
    </React.Fragment>)
    ;
  }

  const carregaView = () => {
    let result = <></>

    switch (acaoAtual) {
      case EnumVendaComissarioAcoes.Lotes:
        result = viewLotes();
        break;
      case EnumVendaComissarioAcoes.Comprador:
        result = viewComprador();
        break;
      case EnumVendaComissarioAcoes.Confirmacao:
        result = viewConfirmacao();
        break;
      case EnumVendaComissarioAcoes.Sucesso:
        result = viewSucesso();
        break;
      default:
        result = viewLotes();
        break;
    }

    return result;
  }

  return (<>
    <ToastContainer position="top-right" autoClose={8000} />
    <div className="p-grid p-m-0">
      <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
        <div className="p-grid">
          <div className="p-d-flex p-ml-2 p-mt-3 p-mb-1">
            <div className='p-pt-1 p-pr-2'>
              <Icon size='menu' name='userGroupOutline' color='primary' />
            </div>
            <div className='text-title'>
              Área comissário
            </div>
          </div>
          <Divider />
          <div className="p-col-12">
            <Card className="p-shadow-5">
              <span className='text-title-event'>{evento.nome}</span>
              <br />
              <p className='text-infos-order'>Quer vender esse evento online? Copie o link no botão abaixo e compartilhe para registrar uma venda online como comissário!</p>
              <Button label="Copiar Link" className="p-button-primary" onClick={() => { copiarLink() }} />
            </Card>
          </div>
          <div className="p-col-12">
            {
              carregaView()
            }
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default VendaComissarioComponent;