import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { ToastContainer, toast } from 'react-toastify';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import { ClienteService } from '../../services/ClienteService';
import GlobalContext from '../../helpers/GlobalContext';
import { Divider } from 'primereact/divider';
import { EnderecoService } from '../../services/EnderecoService';
import { Endereco } from 'passemix-lib/src/models/Endereco';
import TrocaSenhaForm from './TrocaSenhaForm';
import { UsuarioService } from '../../services/UsuarioService';
import Icon from '../../StyleGuide/Components/Icon';

export interface IContaComponent {
}

const ContaComponent: React.FC<IContaComponent> = (props) => {

	const history = useHistory();
	const { logout } = useContext(GlobalContext);
	const [erro, setErro] = useState('');
	const [cliente, setCliente] = useState<Cliente>(new Cliente());
	const [endereco, setEndereco] = useState<Endereco>(new Endereco());

	const carregarCliente = useCallback(async () => {
		try {

			let service = new ClienteService();
			let response = await service.obterCliente();

			setCliente(response);
		}
		catch (error: any) {

			if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				toast.warn('Falha ao carregar dados');
			}
		}

	}, [history, logout]);

	const carregaEndereco = useCallback(async () => {
		try {

			let service = new EnderecoService();
			let response = await service.obter();

			setEndereco(response);
		}
		catch (error: any) {

			if (error.status === 401) {
				logout();
				history.push("/eventos");
			} else {
				toast.warn('Falha ao carregar endereço');
			}
		}

	}, [history, logout]);

	const finalizarTrocaSenha = async (values: any) => {

		try {
			if (values.senhaAtual !== '') {
				let service = new UsuarioService();
				let response = await service.alterarSenha(values);

				if (response !== undefined) {
					toast.success('Sua senha foi alterada');
				}
			}

		} catch (error: any) {
			setErro(error.data.Message);
			toast.error(`${error.data.Message}`);
		}
	}

	useEffect(() => {
		carregarCliente();
		carregaEndereco();
	}, [carregarCliente, carregaEndereco])

	return (
		<>
			<ToastContainer position="top-right" autoClose={8000} />
			<div className="p-grid p-m-0">
				<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
					<div className="p-d-flex p-ml-2 p-mt-3 p-mb-1">
						<div className='p-pt-1 p-pr-2'>
							<Icon size='menu' name='userOutline' color='primary' />
						</div>
						<div className='text-title'>
							Minha Conta
						</div>
					</div>
					<Card className="p-shadow-4 p-mb-3 p-mt-2">
						<Divider align="left">
							<span className="text-dividers">Meus dados</span>
						</Divider>
						<div className="p-grid p-mt-3">
							<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
								<div className="text-label-info">Nome</div>
								<div className="text-info">{cliente.nome}</div>
							</div>
							<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
								<div className="text-label-info">E-mail</div>
								<div className="text-info">{cliente.email}</div>
							</div>
							<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
								<div className="text-label-info">Celular</div>
								<div className="text-info">{cliente.telefone}</div>
							</div>
						</div>
						{
							endereco?.id !== undefined ?
								<div className='p-mt-4'>
									<Divider align="left">
										<span className="text-dividers">Meu endereço</span>
									</Divider>
									<div className="p-grid p-mt-3">
										<div className="p-col-12 p-sm-12 p-lg-12 p-xl-12">
											<div className="text-label-info">Logradouro</div>
											<div className="text-info">{endereco.logradouro}</div>
										</div>
										<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
											<div className="text-label-info">Número</div>
											<div className="text-info">{endereco.numero}</div>
										</div>
										<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
											<div className="text-label-info">Bairro</div>
											<div className="text-info">{endereco.bairro}</div>
										</div>
										<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
											<div className="text-label-info">Complemento</div>
											<div className="text-info">{endereco.complemento}</div>
										</div>
										<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
											<div className="text-label-info">Cidade-UF</div>
											<div className="text-info">{endereco.cidade}-{endereco.estado}</div>
										</div>
										<div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
											<div className="text-label-info">CEP:</div>
											<div className="text-info">{endereco.cep}</div>
										</div>
									</div>
									<div className="p-d-flex p-jc-end">
										<div className="p-mr-2">
											<Button label="Editar" className="p-button-sm p-button-outlined p-button-secondary" onClick={() => history.push(`/conta/endereco`)} />
										</div>
									</div>
								</div>
								: ''
						}

						<div className='p-mt-5'>
							<Divider align="left">
								<span className="text-dividers">Senha</span>
							</Divider>
							<div className='p-mt-3'>
								<TrocaSenhaForm onSave={finalizarTrocaSenha} erro={erro} />
							</div>
						</div>
					</Card>
				</div>
			</div>
		</>
	);
}

export default ContaComponent;