export class PaymentDataViewModel {

    constructor(){
        this.numeroCartao = '';
        this.nomeCartao = '';
        this.mesExpiracaoCartao = ''
        this.anoExpiracaoCartao = ''
        this.codigoSeguranca = ''
        this.numeroCpf = ''
    }

    public numeroCartao: string;
    public nomeCartao: string;
    public mesExpiracaoCartao: string;
    public anoExpiracaoCartao: string;
    public codigoSeguranca: string;
    public numeroCpf: string;
    public parcela: number;
    public metodoPagamento: any;
    public parcelas: any[];
}