import { Produtor } from "./Produtor";

export class Evento {

    constructor() {
        this.data = new Date();
        this.nome = '';
        this.local = '';
        this.url = '';
    }

    public id: number;
    public nome: string;
    public chave: string;
    public imagem: string;
    public url: string;
    public data: Date;
    public tipo: string;
    public classificacao: string;
    public descricao: string;
    public local: string;
    public endereco: string;
    public numero: string;
    public bairro: string;
    public cidade: string;
    public estado: string;
    public conta: string;
    public tarifaMinimaOnline: number;
    public taxaOnlineCredito: number;
    public isTaxaOnlineCredito: boolean;
    public taxaOnlinePix: number;
    public isTaxaOnlinePix: boolean;
    public taxaRetencaoPix: number;
    public isTaxaRetencaoPix: boolean;
    public taxaRetencaoCredito: number;
    public isTaxaRetencaoCredito: boolean;
    public limiteIngresso: number;
    public limiteCompra: number;
    public idPixel: string;
    public tagGoogle: string;
    public cartao: boolean;
    public pix: boolean;
    public picPay: boolean;
    public produtor: Produtor;
    public visualizacoes: number;
    public horariosPico: string;
    public isVendaOnline: boolean;
    public loteDisponivel: boolean;
    public vendaExclusiva: boolean;
    public arquivoImagem: any;
}