import api from "./Api";
import { Comissario } from "passemix-lib/src/models/Comissario";
import { ResponseHelper } from "../helpers/ResponseHelper";

export class ComissarioService {

    private endpoint = 'v1/comissarios';

    public async validarComissario(chave: string, idProdutor: number): Promise<Comissario> {
        return await api.get<Comissario>(`${this.endpoint}/${chave}/produtor/${idProdutor}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorEventoCliente(idEvento: number): Promise<Comissario> {
        return await api.get<Comissario>(`${this.endpoint}/evento/${idEvento}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}