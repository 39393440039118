import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export interface IContatoComponent {

}

const ContatoComponent: React.FC<IContatoComponent> = (props) => {

	return (
		<>
			<div className="grid">
				<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
					<div className="p-grid">
						<div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
						<h4 className="text-title">Perguntas Frequentes</h4>
						<hr />
						<Accordion>
							<AccordionTab header="Segurança e Pagamento">
								<div className="card-body">
									<span className="text-questao-faq">Quais as formas de pagamento?</span>
									<p>Para um atendimento rápido e eficiente nosso site aceita compras apenas com cartão de crédito e Pix.</p>
									<span className="text-questao-faq">É seguro comprar aqui?</span>
									<p>Nosso site garante que os dados de seu cartão sejam enviados diretamente para a sua operadora e não armazenamos seus dados de cartão, sendo assim você pode comprar com total segurança.</p>
									<span className="text-questao-faq">Posso comprar com cartão de outra pessoa?</span>
									<p>Pode sim! Basta informar os dados corretos na hora do pagamento.</p>
									<span className="text-questao-faq">Por que minha compra está em análise?</span>
									<p>A adquirente realiza uma análise da compra de cada cliente, se encontrar alguma divergência ele fará uma análise mais detalhada.</p>
									<span className="text-questao-faq">Quanto tempo leva a análise?</span>
									<p>A análise pode levar em média 48 horas.</p>
									<span className="text-questao-faq">Por que minha compra foi cancelada após a análise?</span>
									<p>
										Sua compra pode ter sido cancelada pela PasseMix pelos seguintes motivos:
									</p>
									<ul>
										<li>O lote que você comprou acabou enquanto sua compra estava em análise.</li>
										<li>Sua compra entrou em análise faltando menos de 48 horas para o evento.</li>
									</ul>
									Compras canceladas pela PasseMix que foram aprovadas após análise serão estornadas. <br />
									<br />
									<span>Sua compra também pode ser cancelada pela adquirente de pagamento, mas a PasseMix não tem acesso ao motivo. 
									Verifique com o banco emissor do cartão.</span>
									<br /><br />
									<span className="text-questao-faq">Por que minha compra foi rejeitada após a análise?</span>
									<p>Após a análise dos dados de pagamento ele pode ser rejeitado pelos seguintes motivos.</p>
									<ul>
										<li>O pagamento não foi aprovado na validação de segurança da adquirente.</li>
										<li>O cartão recusou o pagamento.</li>
									</ul>
									<br />
									<span className="text-questao-faq">Qual o motivo da rejeição de pagamento?</span>
									<p>A rejeição do pagamento pode ser, dados informados incorretamente, rejeitado pela a análise de risco, rejeitado pelo banco emissor do cartão, saldo disponível e etc.</p>
									<br />
								</div>
							</AccordionTab>
							<AccordionTab header="Pagamento Cartão de Crédito">
								<div className="card-body">
									<span className="text-questao-faq">Por que a compra rejeitada consta no extrato do meu cartão?</span>
									<p>Cada cartão demonstra de uma forma, consulte o banco emissor do cartão o prazo que leva para que a operação seja revertida no seu extrato.</p>
									<span className="text-questao-faq">Por que o estorno da compra não consta no extrato do meu cartão?</span>
									<p>Essa operação depende do banco emisor do cartão, cada um tem seu prazo para demonstrar no extrato do cartão, consulte o seu banco emissor.</p>
								</div>
							</AccordionTab>
							<AccordionTab header="Pagamento Pix">
								<div className="card-body">
									<span className="text-questao-faq">Perdi a chave pix e agora?</span>
									<p>A chave pix é única para cada compra, caso tenha perdido durante a compra, será necessário realizar uma nova compra para efetuar o pagamento.</p>
									<span className="text-questao-faq">A chave pix está inválida, e agora?</span>
									<p>A chave pix gerada durante a compra tem validade de 10 minutos, caso não seja paga a mesma irá vencer e a compra será cancelada.</p>
									<span className="text-questao-faq">Paguei no pix, mas preciso cancelar, como recebo de volta?</span>
									<p>O estorno de uma compra feita com pix será feito diretamente para conta de origem do pagamento. Portanto, não será necessário a sua chave pix.</p>
								</div>
							</AccordionTab>
							<AccordionTab header="Meus Ingressos">
								<div className="card-body">
									<span className="text-questao-faq">Onde ficam meus ingressos?</span>
									<p>Ao finalizar uma compra com pagamento aprovado, acesse seu cadastro para visualizar seus ingressos.</p>
									<span className="text-questao-faq">Onde fica meu cadastro?</span>
									<p>No menu superior clique na opção "Entrar"</p>
									<p>Entre com seu e-mail e senha.</p>
									<p>Dentro de sua conta você encontra todas as suas compras e seus respectivos ingressos.</p>
								</div>
							</AccordionTab>
							<AccordionTab header="Segurança do Ingresso">
								<div className="card-body">
									<span className="text-questao-faq">Quais cuidados preciso ter?</span>
									<p>Não divulgar seu ingresso em redes sociais de forma alguma. Cada ingresso tem validade para um participante, após validado na entrada o mesmo não tem valor.</p>
									<span className="text-questao-faq">Perdi ou roubaram meu ingresso e agora?</span>
									<p>A segurança e confidencialidade de cada ingresso é de sua responsabilidade. Não restituimos de forma alguma perda, roubo ou extravio de ingresso.</p>
									<span className="text-questao-faq">Posso transferir meu ingresso para outra pessoa?</span>
									<p>Por segurança os ingressos são emitidos com o nome e CPF do titular do cadastro.</p>
									<p>A conferência da titularidade no acesso ao evento depende das regras de cada evento, portanto devem ser consultadas as regras do evento.</p>
									<p>Já para o caso de ingresso meia-entrada ou outros descontos/benefícios, é obrigatório que a pessoa que for utilizar o ingresso apresente o documento de comprovação em seu próprio nome.</p>
								</div>
							</AccordionTab>
							<AccordionTab header="Cancelar compra">
								<div className="card-body">
									<span className="text-questao-faq">Posso cancelar minha compra feita no site?</span>
									<p>Conforme o código de Defesa do Consumidor (Artigo 49), somente serão aceitos pedidos de cancelamentos
										de compras solicitados até 7 dias após o pedido, desde que não ultrapasse o limite de 48hs antes do
										início do evento. 
										<br />
										Após o prazo mencionado acima, não é possível efetuar o cancelamento da compra.
										<br /><br />
										A solicitação deve ser feita pelo titular da compra através do e-mail: <a href="mailto:contato@passemix.com.br?subject=Cancelamento de compra">contato@passemix.com.br</a>.
										<br />
										Informe os seguintes dados: 
										<ul>
											<li>Nome completo do titular da compra;</li>
											<li>CPF do titular da compra;</li>
											<li>Código da compra;</li>
											<li>Forma de pagamento;</li>
											<li>Quatro ultimos números do cartão utilizado;</li>
										</ul>
									</p>
									<span className="text-questao-faq">O evento foi cancelado e agora?</span>
									<p>A devolução de valores por cancelamento do evento é de inteira resposabilidade do organizador do evento.</p>
									<span className="text-questao-faq">O evento foi alterado e agora?</span>
									<p>A alteração de data, hora ou atração é de inteira resposabilidade do organizador do evento. Solicite a devolução do valor ao mesmo.</p>
								</div>
							</AccordionTab>
							<AccordionTab header="Troca de Participante">
								<div className="card-body">
									<span className="text-questao-faq">Posso trocar o participante de um ingresso?</span>
									<p>Trocas de titularidade poderão ser realizadas apenas uma vez, essa opção fica disponível até 24 horas antes do início do evento.</p>
									<span className="text-questao-faq">Como trocar um participante?</span>
									<p>Para efetuar a alteração, basta acessar a sua conta e ir até o ingresso que deseja alterar, nele irá encontrar a opção "Alterar Participante".</p>
									<p>Preencha os dados do novo participante e confira os dados preenchidos antes de salvar!</p>
								</div>
							</AccordionTab>
						</Accordion>
						</div>
						<div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
							<h4 className="text-title">Contato</h4>
							<hr />
							<div className="p-p-3 p-text-center">
								<h4>Consulte as perguntas frequentes.</h4>
								<p>Caso não encontre uma resposta, entre em contato conosco através do e-mail <a href="mailto:contato@passemix.com.br?subject=Contato do site">contato@passemix.com.br</a></p>
							</div>
						</div>
					</div>
				</div>
				<a href="https://api.whatsapp.com/send/?phone=5535998768359" target="_blank" rel="noreferrer" className='botao-whatsapp'>
					<FontAwesomeIcon icon={faWhatsapp} style={{marginTop: '14px'}} />
				</a>
			</div>
		</>
	);
}

export default ContatoComponent;