import React from 'react';
import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom';
import EventoComponent from '../components/Evento/EventoComponent';
import LayoutEventoComponent from '../components/Layout/LayoutEventoComponent';

const EventoContainer: React.FC = () => {
	
	const { search } = useLocation();
	const params = queryString.parse(search)
	const { nomeEvento } = useParams<{ nomeEvento: string }>();

	const conteudo =
		<React.Fragment>
			<EventoComponent nomeEvento={nomeEvento} chaveComissario={params.chave} />
		</React.Fragment>

	return (
		<>
			<LayoutEventoComponent content={conteudo} />
		</>
	);
}

export default EventoContainer;