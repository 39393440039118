import React, {  } from 'react';
import logo from '../../content/img/logo.svg';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

export interface ILayoutEventoComponent {
	content: React.ReactFragment
}

const LayoutEventoComponent: React.FC<ILayoutEventoComponent> = (props) => {

	let location = useLocation();

	ReactGA.initialize('UA-72925122-1');
	ReactGA.pageview(location.pathname);
	
	ReactGA4.initialize("G-1N7RPBS7X0");
	ReactGA.send({ hitType: "pageview", page: location.pathname });
	

	return (
		<>
			<div className="container">
				<div className="container-content-evento">
					{props.content}
				</div>
			</div>
			<div className="container-footer">
				<div className="grid">
					<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                        <div className="footer-copy">
                            <div className="p-d-flex p-jc-center">
                                <div className="p-mr-2">
                                    <a href="/"><img className="logo" src={logo} alt="Logo" /></a>
                                </div>
                                <div className="p-mr-2">•</div>
                                <div>
                                    <a className="hover:underline" href="/meus-ingressos">Meus ingressos</a>
                                </div>
                            </div>							
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default LayoutEventoComponent;