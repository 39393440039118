import React from 'react';
import EnderecoComponent from '../components/Endereco/EnderecoComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const EnderecoContainer: React.FC = () => {
	
	const conteudo =
		<React.Fragment>
			<EnderecoComponent />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default EnderecoContainer;