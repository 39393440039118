import * as Yup from "yup"
import { ErrorMessage, FieldArray, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Venda } from 'passemix-lib/src/models/Venda';
import { Card } from 'primereact/card';
import { VendaService } from "../../services/VendaService";
import { Dropdown } from "primereact/dropdown";
import { isValid as isValidCpf } from "@fnando/cpf";

export interface IConfirmacaoForm {
    venda: Venda;
    onSave: any;
}

const innerForm = (props: IConfirmacaoForm & FormikProps<Venda>) => {
    
    const { values } = props;

    let isNomeado = false;

    props.venda.itensVenda.forEach(element => {
        if(element.ticket.participante !== '')
            isNomeado = true;
    });

    return (
        <Form>
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <FieldArray name="itensVenda" render={arrayHelpers => (
                        values.itensVenda.map((item, index) => (
                            <Card className="p-shadow-5 p-mb-4" key={index}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="text-label-info">Lote</div>
                                        <div className="text-info p-text-nowrap p-text-truncate">{item.lote.nome}</div>
                                    </div>
                                    <div className="p-col-6 p-sm-6 p-lg-4 p-xl-4">
                                        <div className="text-label-info">Tipo</div>
                                        <div className="text-info p-text-nowrap p-text-truncate">{item.lote.tipoIngresso}</div>
                                    </div>
                                    <div className="p-col-6 p-sm-6 p-lg-4 p-xl-4">
                                        <div className="text-label-info">Setor</div>
                                        <div className="text-info p-text-nowrap p-text-truncate">{item.lote.setor}</div>
                                    </div>
                                    <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                                        <div className="label">Nome completo</div>
                                        <InputText  name={`itensVenda.${index}.ticket.participante`} value={item.ticket.participante} disabled={isNomeado} onChange={props.handleChange} />
                                        <small className="invalid"><ErrorMessage name={`itensVenda.${index}.ticket.participante`} /></small>
                                    </div>
                                    <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                                        <div className="label">CPF</div>
                                        <InputText name={`itensVenda.${index}.ticket.documento`} value={item.ticket.documento} disabled={isNomeado} onChange={props.handleChange} />
                                        <small className="invalid"><ErrorMessage name={`itensVenda.${index}.ticket.documento`} /></small>
                                    </div>
                                    {
                                        item.lote.modelo ?
                                        <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                                            <div className="label">{item.lote.rotuloModelo}</div>
                                            <Dropdown
                                                name={`itensVenda.${index}.ticket.modelo`}
                                                value={item.ticket.modelo} 
                                                options={item.lote.modelos.split(",")} 
                                                onChange={props.handleChange} 
                                                placeholder="Selecione o modelo"
                                            />
                                            <small className="invalid"><ErrorMessage name={`itensVenda.${index}.ticket.participante`} /></small>
                                        </div> : ''
                                    }
                                </div>
                            </Card>
                        ))
                    )} />
                </div>
                <div className="p-col-12 p-md-12 p-lg-9 p-xl-9">
                    {
                        (props.errors && !Array.isArray(props.errors.itensVenda)) ? 
                        <span className="invalid-confirmacao">{props.errors.itensVenda}</span> : ''
                    }
                </div>
                <div className="p-col-12 p-md-12 p-xl-3 p-lg-3 p-mt-3">
                    <Button type="submit" label="Salvar" className="p-button-primary" disabled={props.isSubmitting || isNomeado} loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const verificaParticipanteDuplicado = (lista: any) : boolean => {

    let resultado = true;
    lista.forEach(item => {
        let grupoParticipante = lista.filter(x => x.ticket.participante === item.ticket.participante);
        if(grupoParticipante.length > 1)
            resultado = false;
    });
   
    return resultado;
}

const verificaDocumentoDuplicado = (lista: any) : boolean => {

    let resultado = true;
    lista.forEach(item => {
        let grupoParticipante = lista.filter(x => x.ticket.documento === item.ticket.documento);
        if(grupoParticipante.length > 1)
            resultado = false;
    });
   
    return resultado;
}

const validationForm = Yup.object().shape({

    itensVenda: Yup.array()
        .of(
          Yup.object().shape({
            ticket: Yup.object().shape({
                participante: Yup.string()
                  .min(4, 'Nome completo')
                  .required('Campo obrigatório'),
                documento: Yup.string()
                  .required('Campo obrigatório')
                  .test("cpf-valido", "CPF inválido",(value) => { return value !== undefined ? isValidCpf(value) : false })
              })
          })
        ).test("checkParticipante", "Participantes duplicado", (lista) => verificaParticipanteDuplicado(lista))
        .test("checkParticipante", "Documentos duplicado", (lista) => verificaDocumentoDuplicado(lista))
});

const ConfirmacaoForm = withFormik<IConfirmacaoForm, Venda>({

    // Mapeamento das propriedades para os campos
    mapPropsToValues: (props) => {
        let venda = props.venda

        venda.itensVenda.forEach(element => {
            element.ticket.participante = element.ticket.participante !== undefined ? element.ticket.participante : '';
            element.ticket.documento = element.ticket.documento !== undefined ? element.ticket.documento : '';
        });

        return venda;
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props, resetForm }) => {
        try {
            let service = new VendaService();
            let response = await service.confirmar(values);

            if(response !== undefined)
                resetForm();

            props.onSave(values);
        }
        catch (error) {
            console.log(error)
        }
    }
})(innerForm);

export default ConfirmacaoForm;