import { Divider } from 'primereact/divider';
import React from 'react';

// import { Container } from './styles';

const TermosComponent: React.FC = () => {
	return (
		<>
			<div className="grid">
				<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2 p-text-justify">
					<h4 className="text-title">Termos de Uso da Plataforma PasseMix</h4>
					<Divider />
					<p className="text-title-md">1. DA PLATAFORMA PASSEMIX</p>
					<p className='text-detalhe-termo'>
						1.1 A PASSEMIX é apenas uma intermediária de compra de ingressos on-line para eventos disponibilizados pelos 
						ORGANIZADORES no website www.passemix.com.br. Fica esclarecido desde já ao CONSUMIDOR - o qual se declara 
						ciente - que o serviço oferecido pela PASSEMIX se relaciona apenas à intermediação com pagamento on-line 
						de ingressos de eventos, não se envolvendo em nenhum momento com o evento, toda e qualquer reclamação seja 
						judicial ou administrativa, referente ao evento e sua atrações, adiamentos ou cancelamentos, e qualquer outra 
						situação decorrente do evento em si, deverá ser direcionada direta e exclusivamente ao ORGANIZADOR do evento 
						e que também será o único responsável para responder por qualquer danos e/ou prejuízo causados ao CONSUMIDOR.
					</p>
					<p className='text-detalhe-termo'>
						1.2 Fica esclarecido, também, que a PASSEMIX não assegura ou garante o cumprimento de qualquer oferta realizada 
						pelo(s) ORGANIZADORES, sendo responsável apenas pelo serviço de intermediação de pagamento online das compras 
						de ingressos. A PASSEMIX não será responsável por qualquer dano e/ou prejuízo que o CONSUMIDOR possa sofrer 
						devido ao(s) ingresso(s) adquirido(s) do(s) ORGANIZADORES através da PASSEMIX.
					</p>

					<p className="text-title-md p-mt-5">2. DA REMUNERAÇÃO DOS SERVIÇOS PRESTADOS PELA PASSEMIX</p>
					<p className='text-detalhe-termo'>
						2.1 A utilização dos serviços da PASSEMIX para compra de ingressos constitui uma opção do CONSUMIDOR, não havendo 
						qualquer obrigatoriedade na utilização dos serviços prestados através da plataforma PASSEMIX, podendo realizar a 
						compra do ingresso em outros pontos de venda disponibilizado pelo ORGANIZADOR do evento.
					</p>
					<p className='text-detalhe-termo'>
						2.2 O serviço prestado pela plataforma PASSEMIX é um serviço remunerado que poderá ser pago diretamente pelo 
						ORGANIZADOR do evento na modalidade comissão, ou cobrado diretamente do CONSUMIDOR através da taxa de serviço, 
						acrescendo-se o valor da taxa ao valor do ingresso, conforme previsão do art. 27, §2º e §4º, inciso V da Lei Federal nº 11.771/2008.
					</p>
					<p className='text-detalhe-termo'>
						2.3 A taxa de serviço, quando cobrada diretamente do CONSUMIDOR, será informada no ato da compra em separado do valor de face do ingresso.
					</p>
					<p className='text-detalhe-termo'>
						2.4 Ao aceitar o presente contrato antes da conclusão da compra o CONSUMIDOR declara estar ciente da legalidade da taxa de serviço a qual 
						se faz indispensável para remuneração do serviço a ser prestado ao CONSUMIDOR, caracterizando ato de má-fé qualquer ação administrativa ou 
						judicial do consumidor para exigir a devolução de taxa de serviço regulamentada por Lei Federal, podendo a PASSEMIX exigir o ressarcimento 
						dos danos materiais a que der causa o CONSUMIDOR. 
					</p>
					<p className="text-detalhe-termo">
						2.5 Caso o CONSUMIDOR prefira adquirir seus ingressos sem o pagamento da taxa de serviço, poderá optar por não contratar os serviços da 
						PASSEMIX e realizar a compra do ingresso diretamente na bilheteria ou no ponto de venda sem cobrança de taxa indicado pelo ORGANIZADOR 
						na página oficial do evento, constituindo responsabilidade do ORGANIZADOR a divulgação dos demais pontos de venda para o referido evento. 
					</p>
					<p className="text-detalhe-termo">
						2.6 Os valores dos ingressos adquiridos pelos CONSUMIDORES através da plataforma PASSEMIX são repassados ao ORGANIZADOR do evento, o qual é o 
						único responsável pela organização e entrega do evento conforme ofertado, além do pagamento de impostos e demais responsabilidades legais 
						relacionadas ao evento. 
					</p>

					<p className="text-title-md p-mt-5">3. DO PROCEDIMENTO DE COMPRA</p>
					<p className="text-detalhe-termo">
						3.1 Para utilização da plataforma PASSEMIX o CONSUMIDOR deverá ser cadastrado no site www.passemix.com.br e declara que suas informações de 
						cadastro são verdadeiras, podendo a PASSEMIX realizar o cancelamento do cadastro no caso de inconsistência de informações, ou utilização de 
						dados de terceiros, não sendo devido qualquer tipo de indenização pelo cancelamento da referida conta.
					</p>
					<p className="text-detalhe-termo">
						3.2 O CONSUMIDOR deve manter sigilo de sua senha na plataforma PASSEMIX, sendo o único responsável pelo uso indevido de seu cadastro.
					</p>
					<p className="text-detalhe-termo">
						3.3 Ao efetuar o login o CONSUMIDOR estará realizando o pagamento em ambiente seguro por meio de cartão de crédito, Pix ou PicPay, sendo estas formas de pagamento sujeitas à disponibilidade conforme cada evento.
					</p>
					<p className="text-detalhe-termo">
						3.4 A PASSEMIX sugere que a compra de ingressos seja realizada com antecedência, não se responsabilizando por possíveis problemas nos sistemas 
						de autenticação on-line das operadoras de cartão de crédito ou instituições bancárias que porventura possam impedir a efetivação imediata da operação. 
					</p>
					<p className="text-detalhe-termo">
						3.5 O CONSUMIDOR declara ser maior de idade e plenamente capaz para utilização da plataforma PASSEMIX, se comprometendo 
						a realizar o cadastro com dados corretos, completos e atualizados, sendo vedado o uso de dados de terceiros.
					</p>
					<p className="text-detalhe-termo">
						3.6 Ao adquirir um ingresso para evento através da plataforma PASSEMIX, o CONSUMIDOR se compromete a realizar o pagamento dos valores contratados. 
						Identificados casos de má-fé, estará o CONSUMIDOR sujeito as medidas judiciais cíveis e criminais cabíveis. 
					</p>
					<p className="text-detalhe-termo">
						3.7 Na hipótese de cancelamento da compra, a devolução dos valores pagos será solicitada pela PASSEMIX à operadora no prazo de até 20 (vinte) dias 
						a contar da solicitação do cliente, e será realizada pelo mesmo meio de pagamento utilizado na compra, sendo que nas compras via cartão de crédito 
						este prazo poderá variar conforme data de fechamento da fatura. 
					</p>
					<p className="text-detalhe-termo">
						3.8 Os prazos estimados para solicitação de reembolso por cancelamentos de pedido poderão sofrer variações em decorrência de situações imprevisíveis, 
						caso fortuito ou força maior que resultem em aumento do volume médio de solicitações pelos consumidores.
					</p>
					<p className="text-detalhe-termo">
						3.9 A PASSEMIX e as operadoras de cartão de crédito se reservam o direito de cancelar a compra realizada pelo CONSUMIDOR, gerando o cancelamento 
						do ingresso digital, nas hipóteses de inconsistência de dados, utilização indevida de cupons promocionais, utilização de cartão de terceiros, 
						utilização de cadastro de terceiros, bem como naquelas em que a análises de segurança da compra pelo sistema antifraude assim o indicar, fato 
						que será comunicado ao CONSUMIDOR através dos canais de atendimento da PASSEMIX. 
					</p>
					<p className="text-detalhe-termo">
						3.10 Cabe ao CONSUMIDOR verificar o status de sua compra na plataforma PASSEMIX antes de se dirigir ao evento, não sendo cabível qualquer 
						indenização por danos morais nas hipóteses de cancelamento da compra decorrentes da análise de segurança. 
					</p>
					<p className="text-detalhe-termo">
						3.11 Todas as informações necessárias ao CONSUMIDOR estarão disponíveis na plataforma PASSEMIX, em ambiente seguro após o login do CONSUMIDOR, 
						local onde poderá gerenciar suas compras e acessar seu ingresso digital, estando a PASSEMIX isenta de qualquer obrigação de envio de e-mails 
						de confirmação de compra, ingresso digital ou demais informações. 
					</p>
					<p className="text-detalhe-termo">
						3.12 O ingresso digital é pessoal, devendo ser nomeado para o consumidor que irá acessar o evento, sendo obrigatória a apresentação 
						de documento oficial com foto para comprovar a titularidade.
					</p>
					<p className='text-detalhe-termo'>
						3.13 O CONSUMIDOR tem total responsabilidade pela segurança e confidencialidade dos ingressos comprados. 
						Cada ingresso tem validade para apenas 1 (um) participante e o mesmo será validado na entrada do evento. 
						A cópia ou compartilhamento de ingressos implica no bloqueio da entrada do evento uma vez que já tiver sido utilizado.
					</p>
					<p className="text-detalhe-termo">
						3.14 A PASSEMIX se reservam o direito de cancelar e ou estornar a compra realizada pelo CONSUMIDOR, nas hipóteses de esgotar os ingressos adquiridos 
						ou anteceder 72 (setenta e duas) horas ou menos para o inicio do evento durante o período de análise de pagamento feito pela operadora de cartão.
					</p>
					<p className="text-detalhe-termo">
						3.15 O CONSUMIDOR declara ter ciência de que a PASSEMIX não é organizadora de eventos, mas tão somente plataforma de mediação de pagamentos 
						para aquisição dos ingressos de eventos dos ORGANIZADORES. Eventuais reclamações ou ações judiciais decorrentes de fatos do evento deverão 
						ser direcionadas exclusivamente ao ORGANIZADOR do evento, o qual está devidamente identificado no ingresso digital ou ingresso físico.
					</p>
					
					<p className="text-title-md p-mt-5">4. DESISTÊNCIA E CANCELAMENTO DA COMPRA</p>
					<p className='text-detalhe-termo'>
						4.1 O CONSUMIDOR poderá exercer o direito de arrependimento da compra no prazo de até 7 (sete) dias contados da compra, 
						desde que observado o prazo mínimo de 48 (quarenta e oito) horas anterior ao evento.
					</p>
					<p className='text-detalhe-termo'>
						4.2 O cancelamento da compra deverá ser formalizado por escrito através do e-mail contato@passemix.com.br contendo as informações da compra.
					</p>
					<p className='text-detalhe-termo'>
						4.3 Ocorrendo recusa por parte do CONSUMIDOR em realizar o procedimento de cancelamento conforme as instruções, não poderá reclamar qualquer
						responsabilização da PASSEMIX pelo não atendimento da solicitação de devolução, por se tratar de ato de culpa exclusiva do CONSUMIDOR.
					</p>
					<p className='text-detalhe-termo'>
						4.4 O estorno dos valores da compra será realizado através da mesma forma de pagamento utilizada pelo CONSUMIDOR e no caso de compra via Pix os valores serão estornados na conta de origem da transferência.
					</p>
					<p className='text-detalhe-termo'>
						4.5 Todas as reclamações e pedidos de desistência realizados após o limite de 48 (quarenta e oito) horas que antecedem o evento 
						deverão ser encaminhados diretamente à organização do evento, estando a PASSEMIX isenta de qualquer responsabilidade na apreciação do pedido.
					</p>
					<p className='text-detalhe-termo'>
						4.5 O não comparecimento no evento não dará direito à troca do mesmo ou estorno dos valores pagos também como taxa de conveniência.
					</p>
					
					<p className="text-title-md p-mt-5">5. RESPONSABILIDADE DO ORGANIZADOR</p>
					<p className='text-detalhe-termo'>
						5.1 Ao realizar o cadastro do evento na plataforma PASSEMIX, o ORGANIZADOR declara ser o titular de todos os direitos referentes ao evento inclusive direitos autorais.
					</p>
					<p className='text-detalhe-termo'>
						5.2 O ORGANIZADOR declara ainda ser o legítimo titular do evento publicado, respondendo por todos os atos e/ou danos decorrentes do evento anunciado na plataforma PASSEMIX.
					</p>
					<p className='text-detalhe-termo'>
						5.3 Toda a informação disponibilizada, publicidade, vídeo ou banner referente ao evento divulgado na plataforma PASSEMIX constitui conteúdo de 
						responsabilidade exclusiva do ORGANIZADOR, não tendo a PASSEMIX qualquer ingerência sobre o conteúdo de informação ou publicidade do evento 
						ofertado ou sua insuficiência de informações, cabendo única a exclusivamente ao ORGANIZADOR do evento a responsabilização nos termos dos 
						arts. 12 e 14 do Código de Defesa do Consumidor. 
					</p>
					<p className='text-detalhe-termo'>
						5.4 Todas as informações e publicidades constantes no site referentes ao evento cadastrado na plataforma PASSEMIX são incluídas pelo ORGANIZADOR 
						e são de sua responsabilidade exclusiva, o qual responde por eventuais vícios de qualidade e demais danos decorrentes do evento, caso existentes.
					</p>
					<p className='text-detalhe-termo'>
						5.5 A venda de ingressos com o benefício da modalidade meia-entrada constitui responsabilidade do ORGANIZADOR do evento, nos termos da 
						legislação da meia-entrada vigente. 
					</p>

					<p className="text-title-md p-mt-5">6. DAS CONDIÇÕES GERAIS DE USO</p>
					<p className='text-detalhe-termo'>
						6.1 As regras e condições de uso ora apresentadas poderão ser alteradas a qualquer tempo sem necessidade de comunicação prévia da PASSEMIX. 
						Ao realizar o cadastro na plataforma PASSEMIX o CONSUMIDOR estará concordando expressamente com as regras e condições de utilização ora 
						apresentadas, nos termos da legislação vigente. 
					</p>
					<p className='text-detalhe-termo'>
						6.2 A PASSEMIX se reserva o direito de bloquear o cadastro do CONSUMIDOR e impedir usos futuros, cancelar a compra de ingressos conforme 
						análise de segurança, bem como tomar as medidas judiciais cabíveis, na hipótese de violação das regras e condições explicitadas neste contrato, 
						não sendo cabível qualquer pedido de indenização em decorrência do cancelamento do cadastro.
					</p>
					<p className='text-detalhe-termo'>
						6.3 Visando a proteção dos direitos dos USUÁRIOS e de terceiros, caso ocorra a tentativa ou a efetiva utilização indevida dos serviços da 
						plataforma, a PASSEMIX poderá indicar os dados do USUÁRIO às autoridades públicas, aos serviços de proteção ao crédito, dentre outros, para 
						início dos procedimentos legais e administrativos cabíveis. 
					</p>
					<p className='text-detalhe-termo'>
						6.4 Caso qualquer cláusula do presente termo seja considerada nula ou sem efeito, não resultará na nulidade total do termo de uso, permanecendo 
						em vigor as demais disposições, direitos e obrigações. 
					</p>
					<p className='text-detalhe-termo'>
						6.5 A PASSEMIX se reserva o direito de processar os seguintes dados de usuário, nome, CPF, telefone e e-mail, para que possamos identificar o
						consumidor que está realizando a compra de ingressos.
					</p>
					<p className='text-detalhe-termo'>
						6.6 O CONSUMIDOR pode solicitar a exclusão completa dos seus dados pessoais bem como da sua conta através do e-mail contato@passemix.com.br. 
					</p>
				</div>
			</div>
		</>
	);
}

export default TermosComponent;