import api from "./Api";
import { Cliente } from "passemix-lib/src/models/Cliente";
import { ResponseHelper } from "../helpers/ResponseHelper";

export class ClienteService {
    
    private endpoint = 'v1/clientes';

    public async obterCliente(): Promise<Cliente> {
        return await api.get<Cliente>(`${this.endpoint}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async buscarClientePorEmailOuCpf(parametro: string): Promise<Cliente> {
        return await api.get<Cliente>(`${this.endpoint}/buscar/${parametro}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async verificarCpf(cpf: string): Promise<string> {
        return await api.get<string>(`${this.endpoint}/verifica_cpf/${cpf}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async buscarPorCpf(cpf: string): Promise<Cliente> {
        return await api.get<Cliente>(`${this.endpoint}/buscar_cpf/${cpf}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrar(cliente: Cliente): Promise<Cliente> {
        return await api.post<Cliente>(`${this.endpoint}`, cliente)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrarVendaDigital(cliente: Cliente): Promise<Cliente> {
        return await api.post<Cliente>(`${this.endpoint}/venda_digital`, cliente)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async atualizar(cliente: Cliente): Promise<Cliente> {
        return await api.put<Cliente>(`${this.endpoint}`, cliente)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}