import { ResponseHelper } from "../helpers/ResponseHelper";
import { Operacao } from "passemix-lib/src/models/Operacao";
import api from "./Api";

export class OperacaoService {
    
    private endpoint = 'v1/operacoes';

    public async obter(agregado: string): Promise<Operacao> {
        return await api.get<Operacao>(`${this.endpoint}/${agregado}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}