import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Icon from '../../StyleGuide/Components/Icon';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import ImageGeneric from '../../content/img/generic.png'
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { Evento } from 'passemix-lib/src/models/Evento';
import { EventoService } from '../../services/EventoService';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../helpers/GlobalContext';
import { VendaService } from '../../services/VendaService';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import syncTime from '../../content/img/sync_time.svg';

export enum EnumComissarioAcoes {
  Venda,
  Extrato
}

const ComissarioComponent: React.FC = () => {

  const history = useHistory();
  const { setEvento, logout } = useContext(GlobalContext);
  const [eventos, setEventos] = useState<Evento[]>();
  const [acaoAtual, setAcaoAtual] = useState(EnumComissarioAcoes.Venda);

  const [extratoFiltrado, setExtratoFiltrado] = useState(new Array<any>());
  const [showFilter, setShowFilter] = useState(false);
  const [extrato, setExtrato] = useState<any[]>(new Array<any>());

  const [eventosFiltro, setEventosFiltro] = useState<string[]>();
  const [meiosPagamento, setMeiosPagamento] = useState<string[]>();

  const [filtroEvento, setFiltroPdv] = useState<any>();
  const [filtroMeioPagamento, setFiltroMeioPagamento] = useState<any>();
  const [filtroData, setFiltroData] = useState<any>();

  const [valorTotal, setValorTotal] = useState(0);
  const [qtdIngressos, setQtdIngressos] = useState(0);
  const [qtdIngressosOnline, setQtdIngressosOnline] = useState(0);

  const carregaEventos = useCallback(async () => {
    try {
      let service = new EventoService();
      let response = await service.obterEventosClienteComissario();

      setEventos(response);
    }
    catch (error: any) {
      if (error.status === 401) {
				logout();
				history.push("/login");
			} else {
				console.log(error);
			}
    }
  }, [logout, history])

  const carregarExtrato = useCallback(async () => {
    try {
      let service = new VendaService();
      let response = await service.obterExtratoComissario();
      
      if(response !== undefined)
      {
        setExtrato(response);
        setExtratoFiltrado(response);

        let eventoLista = new Array<any>();

        Array.from(new Set(response.map(x => x.evento))).forEach(element => {
          eventoLista.push({ nome: element })
        });

        setEventosFiltro(eventoLista);

        let meioPagamentoLista = Array.from(new Set(response.map(x => x.meioPagamento)));
        setMeiosPagamento(meioPagamentoLista);
      }
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => { 
    carregaEventos();
    carregarExtrato(); 
  }, [carregaEventos, carregarExtrato])

  addLocale('br', {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  });
  
  const ativarFiltro = () => {

    setFiltroPdv(null);
    setFiltroMeioPagamento(null);
    setFiltroData(null);

    setShowFilter(!showFilter)
  }

  const novaVendaComissario = (evento: Evento) => {
    setEvento(evento);
    history.push("/area-comissario/venda");
  }

  const filtrarVendas = useCallback((extrato: any[]) => {

    let vendasResult = extrato;

    if (filtroEvento && filtroEvento.length)
      vendasResult = extrato.filter(x => filtroEvento.includes(x.evento));

    if (filtroMeioPagamento && filtroMeioPagamento.length)
      vendasResult = vendasResult.filter(x => filtroMeioPagamento?.includes(x.meioPagamento));

    if (filtroData) {
      let dataInicial = filtroData[0];

      let dataFinal = filtroData[1];

      if (dataInicial)
        vendasResult = vendasResult.filter(x => UtilsHelper.formatDateTime(x.data, "yyyy-MM-dd") >= UtilsHelper.formatDateTime(dataInicial, "yyyy-MM-dd"));

      if (dataFinal)
        vendasResult = vendasResult.filter(x => UtilsHelper.formatDateTime(x.data, "yyyy-MM-dd") <= UtilsHelper.formatDateTime(dataFinal, "yyyy-MM-dd"));
    }

    setExtratoFiltrado(vendasResult);
    calcularTotais(vendasResult);
  }, [filtroData, filtroMeioPagamento, filtroEvento]);

  useEffect(() => {
    filtrarVendas(extrato!);
  }, [filtroEvento, filtroMeioPagamento, filtroData, filtrarVendas, extrato])

  const calcularTotais = (extrato: any[]) => {

    let total = extrato.reduce((acc, cur) => acc += cur.preco, 0);
    let qtdIngressos = extrato.reduce((acc, cur) => acc += 1, 0);

    let qtdIngressosOnline = extrato.reduce((acc, cur) => acc += (cur.pdv === 'Venda Online' ? 1 : 0), 0);

    setValorTotal(total);
    setQtdIngressos(qtdIngressos);
    setQtdIngressosOnline(qtdIngressosOnline);
  }

  const viewVenda = () => {
    return (<React.Fragment>
      {
        eventos !== undefined ?
        eventos.map((evento, i) =>
          <Card className="p-shadow-5 pointer p-mb-3" key={i} onClick={() => novaVendaComissario(evento)}>
            <div className="p-grid p-m-0">
              <div className='p-col-12'>
                <div className="p-d-flex text-infos-order p-pb-2">
                  <div>
                    <div className="card-evento-imagem-comissario" style={{ backgroundImage: `url(${(evento.imagem) ? `https://passemix-assets.s3.amazonaws.com/eventos/${evento.imagem}` : ImageGeneric})` }}></div>
                  </div>
                  <div className='p-pt-4 p-ml-3' style={{ position: "relative" }}>
                    <div className='vertical-center'>
                      <span className="text-title-event">{evento.nome}</span>
                      <br />
                      <br />
                      <span className="text-info">{UtilsHelper.formatDateTime(evento.data, 'dd/MM/yyyy')}</span> | <span className="text-info">{evento.local}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )
        : ''
      }
      {
        eventos !== undefined && eventos.length === 0 ?
          <div className='p-col-12 p-text-center'>
            <div>
              <img className='p-m-3' style={{ width: "200px" }} src={syncTime} alt="sync" />
            </div>
            <h3>Nenhum evento disponível.</h3>
          </div>
          : ''
      }
    </React.Fragment>);
  }

  const viewExtrato = () => {
    return (<React.Fragment>
      <Card>
        <div className="header-card p-pb-0">
          <div className="p-d-flex p-jc-between">
            <div className="center-vertical">
              <span className="text-label-container">Extrato de vendas</span>
            </div>
            <div>
              <Button icon="pi pi-filter" className="p-button-rounded p-button-icon-only-secondary p-button-text p-m-0" onClick={ativarFiltro} />
            </div>
          </div>
          {
            showFilter ?
              <div className="p-grid p-mt-2 p-mb-2">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
                      <MultiSelect
                        className="multi-select"
                        options={eventosFiltro}
                        value={filtroEvento}
                        placeholder="Filtro de evento"
                        onChange={(e) => setFiltroPdv(e.value)}
                        display="chip"
                        optionLabel='nome'
                        optionValue='nome'
                        filter
                        filterLocale="br"
                        emptyFilterMessage="Nenhum resultado encontrado"
                      />
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
                      <MultiSelect
                        className="multi-select"
                        options={meiosPagamento}
                        value={filtroMeioPagamento}
                        placeholder="Filtro de meio de pagamento"
                        onChange={(e) => setFiltroMeioPagamento(e.value)}
                        display="chip"
                      />
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
                      <Calendar
                        style={{ "width": "100%" }}
                        value={filtroData}
                        locale="br"
                        placeholder='Filtro de data'
                        onChange={(e) => setFiltroData(e.value)}
                        showButtonBar
                        selectionMode="range"
                      />
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                      <div className="p-grid p-m-1 p-p-2 boder-round">
                        <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
                          <div>
                            <span className="label">Valor total</span>
                          </div>
                          <div className="text-value-card">
                            <span>R$ {UtilsHelper.formatCurrency(valorTotal)}</span>
                          </div>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
                          <div>
                            <span className="label">Total ingressos</span>
                          </div>
                          <div className="text-value-card p-pl-1">
                            <span>{qtdIngressos}</span>
                          </div>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
                          <div className="">
                            <span className="label">Qtd. ingresso On-line</span>
                          </div>
                          <div className="text-value-card p-pl-1">
                            <span>{qtdIngressosOnline}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ''
          }
        </div>
        <Divider />
        <div className="p-grid p-mt-2">
          {
            extratoFiltrado.map((item, i) =>
              <div className="p-col-12 p-md-12 p-lg-12 p-xl-12" key={i}>
                <div className="p-grid">
                  <div className="p-col-8 p-md-8 p-lg-6 p-xl-6">
                    <div className="text-title-card">
                      <span>{item.evento}</span>
                    </div>
                    <div className="text-info p-mt-1">
                      <span>{item.pdv} - {item.meioPagamento}</span>
                    </div>
                    <div className="text-info p-mt-1">
                      <span>{UtilsHelper.formatDateTime(item.data, 'dd/MM/yyyy - HH:mm')}</span>
                    </div>
                  </div>
                  <div className="p-col-4 p-md-4 p-lg-6 p-xl-6 p-text-right">
                    <div className="text-info">
                      <span>R$ {UtilsHelper.formatCurrency(item.preco)}</span>
                    </div>
                    <div className="text-info p-mt-1">
                      <span>{item.status}</span>
                    </div>
                    <div className="text-info p-mt-1">
                      <span>{item.lote}</span>
                    </div>
                  </div>
                  <Divider type='dashed' />
                  <div className="p-col-12">
                    <div className="text-info">
                      <span>{item.cliente} - {item.documento}</span>
                    </div>
                  </div>
                </div>
                <Divider />
              </div>
            )
          }
        </div>
      </Card>
    </React.Fragment>);
  }

  const carregaView = () => {
    let result = <></>

    switch (acaoAtual) {
      case EnumComissarioAcoes.Venda:
        result = viewVenda();
        break;
      case EnumComissarioAcoes.Extrato:
        result = viewExtrato();
        break;
      default:
        result = viewVenda();
        break;
    }

    return result;
  }

  return (<>
    <ToastContainer position="top-right" autoClose={8000} />
    <div className="p-grid p-m-0">
      <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
        <div className="p-grid">
          <div className="p-d-flex p-ml-2 p-mt-3 p-mb-1">
            <div className='p-pt-1 p-pr-2'>
              <Icon size='menu' name='userGroupOutline' color='primary' />
            </div>
            <div className='text-title'>
              Área comissário
            </div>
          </div>
          <Divider />
          <div className="p-col-6">
            <Button label="VENDA DE INGRESSOS" className="p-button-primary" style={{ width: "100%" }} onClick={() => setAcaoAtual(EnumComissarioAcoes.Venda)} />
          </div>
          <div className="p-col-6">
            <Button label="EXTRATO DE VENDAS" className="p-button-outlined p-button-secondary" style={{ width: "100%" }} onClick={() => setAcaoAtual(EnumComissarioAcoes.Extrato)} />
          </div>
          <div className="p-col-12">
            {
              carregaView()
            }
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default ComissarioComponent;