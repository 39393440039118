
import React from 'react';
import CheckoutComponent from '../components/Checkout/CheckoutComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const CheckoutContainer: React.FC = () => {
	
	const conteudo = 
	<React.Fragment>
		<CheckoutComponent />
	</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default CheckoutContainer;