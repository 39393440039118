import * as Yup from "yup"
import Swal from 'sweetalert2'
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { isValid as isValidCpf } from "@fnando/cpf";
import { Cliente } from "passemix-lib/src/models/Cliente";
import { ClienteService } from "../../../services/ClienteService";
import { UtilsHelper } from "passemix-lib/src/helpers/UtilsHelper";

export interface IConfirmacaoForm {
    cliente: Cliente;
    onSubmited: any;
}

const innerForm = (props: IConfirmacaoForm & FormikProps<Cliente>) => {
    
    const { values } = props;

    const onChangeTelefone = (event: any) => {
        const { name, value } = event.target;
        let telefoneFormatado = UtilsHelper.formatTelefone(value);
        props.setFieldValue(name, telefoneFormatado);
    }

    const onChangeCpf = (event: any) => {
        const { name, value } = event.target;
        let cpfCnpjFormatado = UtilsHelper.formatCpf(value);
        props.setFieldValue(name, cpfCnpjFormatado);
    }
    
    return (
        <Form onSubmit={props.handleSubmit}>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="label">CPF</div>
                    <InputText type="tel" name="cpf" value={values.cpf} onChange={onChangeCpf} />
                    <small className="invalid"><ErrorMessage name="cpf" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Celular</div>
                    <InputText type="tel" name="telefone" value={values.telefone} onChange={onChangeTelefone} />
                    <small className="invalid"><ErrorMessage name="telefone" /></small>
                </div>
                <div className="p-col-12">
                    <Button type="submit" label='Salvar' className="p-button-primary p-mt-2" disabled={props.isSubmitting} loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    telefone: Yup.string().required("Campo obrigatório"),
    cpf: Yup.string().required("Campo obrigatório")
    .test("cpf-valido", "CPF inválido",(value) => { return value !== undefined ? isValidCpf(value) : false })
});

const ConfirmacaoForm = withFormik<IConfirmacaoForm, Cliente>({
    
    // Mapeamento das propriedades para os campos
    mapPropsToValues: (props) => {
        return props.cliente;
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props, submitForm }) => {
        try 
        {
            var clienteService = new ClienteService();
            props.cliente.cpf = values.cpf;
            props.cliente.telefone = values.telefone;
            await clienteService.atualizar(props.cliente);
            
            props.onSubmited(props.cliente);
        }
        catch (error: any) {
            Swal.fire({
                title: 'Ops',
                text: `${error.data.Message}`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#004399',
            })
        }
    }
})(innerForm);

export default ConfirmacaoForm;