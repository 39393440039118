import React from 'react';
import * as Yup from "yup"
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { Ticket } from 'passemix-lib/src/models/Ticket';
import { TicketService } from '../../services/TicketService';
import { InputText } from 'primereact/inputtext';
import { isValid as isValidCpf } from "@fnando/cpf";

export interface ITrocaParticipanteForm {
    ticket: Ticket;
    onSave: any;
}

const innerForm = (props: ITrocaParticipanteForm & FormikProps<Ticket>) => {

    const { values } = props;

    return (
        <Form>
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                    <div className="label">Nome completo</div>
                    <InputText  name="participante" value={values.participante} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="participante" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                    <div className="label">CPF</div>
                    <InputText name="documento" value={values.documento} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="documento" /></small>
                </div>

                <div className="p-col-12 p-md-12 p-lg-12">
                    <Button type="submit" label={ (props.isSubmitting) ? 'Salvando' : 'Salvar' } disabled={props.isSubmitting} loading={props.isSubmitting} style={{ marginRight: '.25em' }} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    participante: Yup.string()
        .min(4, 'Nome completo')
        .required('Campo obrigatório'),
    documento: Yup.string()
        .required('Campo obrigatório')
        .test("cpf-valido", "CPF inválido",(value) => { return value !== undefined ? isValidCpf(value) : false })
});

const TrocaParticipanteForm = withFormik<ITrocaParticipanteForm, Ticket>({
    // Mapeamento das propriedades para os campos
    mapPropsToValues: ({ticket}) => {
        return ticket;
    },

    validationSchema: validationForm,

    handleSubmit: async (values, {props, resetForm}) => {
        try {
            let service = new TicketService();
            let response = await service.atualizar(values);

            resetForm();
            props.onSave(response);
        }
        catch (error) {
            console.log(error);
        }
    }
})(innerForm);

export default TrocaParticipanteForm;