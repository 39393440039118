import React from 'react';
import HomeComponent from '../components/Home/HomeComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';


const HomeContainer: React.FC = () => {

	const conteudo = 
	<React.Fragment>
		<HomeComponent />
	</React.Fragment>

	return (
		<LayoutComponent content={conteudo} />
	);
}

export default HomeContainer;