import React from 'react';
import { Tokens } from '../../Tokens/Index';
import SVG from 'react-inlinesvg';

export interface IIcon {
    name: string;
    size: string;
    color: string;
    className?: string;
}

const Icon: React.FC<IIcon> = (props) => {

    const getIconSvgColor = (appearance: string) => {
        switch (appearance) {
            case 'primary':
                return Tokens.Colors.passemix.primary
            case 'white':
                return Tokens.Colors.passemix.white
            case 'blue':
                return Tokens.Colors.passemix.blue
            case 'blue_primary':
                return Tokens.Colors.passemix.blue_primary
            case 'blue_secondary':
                return Tokens.Colors.passemix.blue_secondary
            case 'blue_alternative':
                return Tokens.Colors.passemix.blue_alternative
            case 'grey_dark':
                return Tokens.Colors.passemix.grey_dark
            case 'grey_light':
                return Tokens.Colors.passemix.grey_light
            case 'success':
                return Tokens.Colors.passemix.success
            case 'warning':
                return Tokens.Colors.passemix.warning
            case 'warning_light':
                return Tokens.Colors.passemix.warning_light
            case 'danger':
                return Tokens.Colors.passemix.danger
            case 'info':
                return Tokens.Colors.passemix.info
            default:
                return Tokens.Colors.passemix.primary
        }
    }

    const getIconSvgSize = (size: string) => {
        if (size && !Object.keys(Tokens.iconSize).includes(size)) {
            return size
        }

        return Tokens.iconSize[size] || Tokens.iconSize.sm
    }

    return (
        <SVG
            {...props}
            src={Tokens.Icons[props.name !== undefined ? props.name : 'default']}
            width={props.size ? getIconSvgSize(props.size) : ''}
            fill={getIconSvgColor(props.color)}
        />
    )
}

export default Icon;