import React from 'react';
import * as Yup from "yup"
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { Endereco } from 'passemix-lib/src/models/Endereco';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { EnderecoService } from "../../services/EnderecoService";

export interface IEnderecoForm {
    endereco: Endereco;
    onSave: any;
}

const innerForm = (props: IEnderecoForm & FormikProps<Endereco>) => {

    const { values } = props;

    const onBlurCep = async (event: any) => {
        const {value } = event.target;
        
        try {
			let service = new EnderecoService();
			let response = await service.consultarCep(value.replace("-", ""));
            
            if(response.logradouro !== undefined)
            {
                props.setFieldValue(`logradouro`, response.logradouro);
                props.setFieldValue(`bairro`, response.bairro);
                props.setFieldValue(`cidade`, response.localidade);
                props.setFieldValue(`estado`, response.uf);
            }
		}
		catch (error) {
			console.log(error);
		}
    }

    return (
        <Form>
            <Card className="p-shadow-5 p-mb-4">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12 p-md-12 p-lg-3 p-xl-3">
                        <div className="label">CEP</div>
                        <InputText name="cep" value={values.cep} onChange={props.handleChange} onBlur={onBlurCep} />
                        <small className="invalid"><ErrorMessage name="cep" /></small>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                        <div className="label">Logradouro</div>
                        <InputText name="logradouro" value={values.logradouro} onChange={props.handleChange} />
                        <small className="invalid"><ErrorMessage name="logradouro" /></small>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-3 p-xl-3">
                        <div className="label">Número</div>
                        <InputText name="numero" value={values.numero} onChange={props.handleChange} />
                        <small className="invalid"><ErrorMessage name="numero" /></small>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                        <div className="label">Bairro</div>
                        <InputText name="bairro" value={values.bairro} onChange={props.handleChange} />
                        <small className="invalid"><ErrorMessage name="bairro" /></small>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-3 p-xl-3">
                        <div className="label">Complemento</div>
                        <InputText name="complemento" value={values.complemento} onChange={props.handleChange} />
                    </div>
                    <div className="p-col-12 p-md-3 p-lg-3 p-xl-3">
                        <div className="label">Cidade</div>
                        <InputText name="cidade" value={values.cidade} onChange={props.handleChange} />
                        <small className="invalid"><ErrorMessage name="cidade" /></small>
                    </div>
                    <div className="p-col-12 p-md-1 p-lg-2 p-xl-2">
                        <div className="label">Estado</div>
                        <InputText name="estado" value={values.estado} onChange={props.handleChange} />
                        <small className="invalid"><ErrorMessage name="estado" /></small>
                    </div>

                    <div className="p-col-12 p-md-12 p-lg-5 p-xl-5">
                    </div>
                    <div className="p-col-12 p-md-12 p-xl-2 p-lg-2 p-mt-3">
                        <Button type="submit" label="Salvar" className="p-button-primary" disabled={props.isSubmitting} loading={props.isSubmitting} />
                    </div>
                </div>
            </Card>
    </Form>
    );
};

const validationForm = Yup.object().shape({
    cep: Yup.string().required("Campo obrigatório"),
    logradouro: Yup.string().required("Campo obrigatório"),
    numero: Yup.string().required("Campo obrigatório"),
    bairro: Yup.string().required("Campo obrigatório"),
    cidade: Yup.string().required("Campo obrigatório"),
    estado: Yup.string().required("Campo obrigatório")
});

const EnderecoForm = withFormik<IEnderecoForm, Endereco>({
    // Mapeamento das propriedades para os campos
    enableReinitialize: true,

    mapPropsToValues: (props) => {
        return props.endereco !== undefined ? props.endereco : new Endereco();
    },

    validationSchema: validationForm,

    handleSubmit: async (values, {props, resetForm}) => {
        try {

            let service = new EnderecoService();
            let response;

            if(values.id !== undefined){
                response = await service.atualizar(values);
            }else{
                response = await service.cadastrar(values);
            }

            if(response !== undefined)
                resetForm();

            props.onSave(values);
        }
        catch (error) {
            console.log(error);
        }
    }
})(innerForm);

export default EnderecoForm;