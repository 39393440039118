import React from 'react';
import LayoutComponent from '../components/Layout/LayoutComponent';
import VendaComissarioComponent from '../components/Comissario/VendaComissarioComponent';

const VendaComissarioContainer: React.FC = () => {

    const conteudo =
        <React.Fragment>
            <VendaComissarioComponent />
        </React.Fragment>

    return (
        <>
            <LayoutComponent content={conteudo} />
        </>
    );
}

export default VendaComissarioContainer;