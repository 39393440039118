import * as Yup from "yup"
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { isValid as isValidCpf } from "@fnando/cpf";
import { ClienteService } from '../../services/ClienteService';

export interface IClienteVendaForm {
    cliente: Cliente;
    onSave: any;
    onConfirm: any;
}

const innerForm = (props: IClienteVendaForm & FormikProps<Cliente>) => {
    
    const { values } = props;

    const onChangeTelefone = (event: any) => {
        const { name, value } = event.target;
        let telefoneFormatado = UtilsHelper.formatTelefone(value);
        props.setFieldValue(name, telefoneFormatado);
    }

    const onChangeCpf = (event: any) => {
        const { name, value } = event.target;
        let cpfCnpjFormatado = UtilsHelper.formatCpf(value);
        props.setFieldValue(name, cpfCnpjFormatado);
    }

    return (
        <Form>
            <div className="p-grid p-fluid p-text-left">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Nome completo</div>
                    <InputText name="nome" value={values.nome} onChange={props.handleChange} disabled={props.cliente.id !== undefined} />
                    <small className="invalid"><ErrorMessage name="nome" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">CPF</div>
                    <InputText type="tel" name="cpf" value={values.cpf} onChange={onChangeCpf} disabled={props.cliente.id !== undefined} />
                    <small className="invalid"><ErrorMessage name="cpf" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">Celular</div>
                    <InputText type="tel" name="telefone" value={values.telefone} onChange={onChangeTelefone} disabled={props.cliente.id !== undefined} />
                    <small className="invalid"><ErrorMessage name="telefone" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="label">E-mail</div>
                    <InputText type="email" name="email" value={values.email} onChange={props.handleChange}  disabled={props.cliente.id !== undefined}/>
                    <small className="invalid"><ErrorMessage name="email" /></small>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <Button type="submit" label="Confirmar comprador" className='p-button-primary' disabled={props.isSubmitting} loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório")
    .min(8, 'Preencha seu nome completo'),
    cpf: Yup.string().required("Campo obrigatório")
    .test("cpf-valido", "CPF inválido",(value) => { return value !== undefined ? isValidCpf(value) : false }),
    telefone: Yup.string().required("Campo obrigatório"),
    email: Yup.string().email("Email inválido").required("Campo obrigatório")
    .matches(/@(?!gmai\.|hotmai\.|iclou\.)\w+\./, 'Preencha um e-mail válido')
    .test('checkEmailUnique', 'Este e-mail já está registado.', async (value, context) => {
        if(value !== undefined && value.length > 5 && context.parent.id === undefined)
        {
            let service = new ClienteService();
            let response = await service.buscarClientePorEmailOuCpf(value);
            return (response) ? false : true;
        }
        return true;
    }),
});

const ClienteVendaForm = withFormik<IClienteVendaForm, Cliente>({

    enableReinitialize: true,

    // Mapeamento das propriedades para os campos
    mapPropsToValues: (props) => {
        return props.cliente;
    },

    validationSchema: validationForm,
    validateOnChange: false,

    handleSubmit: async (values, { props, resetForm }) => {
        try 
        {
            if(values.id !== undefined)
            {
                props.onConfirm(values);
            }else {
                let service = new ClienteService();
                let response = await service.cadastrarVendaDigital(values);

                if(response !== undefined)
                    resetForm();

                props.onSave(response);
            }
        }
        catch (error) {
            console.log(error)
        }
    }
})(innerForm);

export default ClienteVendaForm;