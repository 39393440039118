import React, { useState } from 'react';
import GlobalContext from './GlobalContext';
import { GlobalService } from '../services/GlobalService';
import { Evento } from 'passemix-lib/src/models/Evento';
import { Carrinho } from '../models/Carrinho';

const ProvideContext: React.FC = ({ children }: any) => {
	
	const [carrinho, setCarrinho] = useState(new Carrinho());
	const [logado, setLogado] = useState(GlobalService.isAutenticado())
	const [evento, setEvento] = useState(new Evento());

	const logout = () => {
		GlobalService.closeSessao();
		setLogado(false);
	}
	
	return (
		<GlobalContext.Provider value={{carrinho, setCarrinho, logado, setLogado, logout, evento, setEvento}}>
			{children}
		</GlobalContext.Provider>
	);
}

export default ProvideContext;