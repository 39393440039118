
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { GlobalService } from '../services/GlobalService';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }: any) => 
{
    const renderComponentLogin = () => <Redirect to={{ pathname: "/login" }} />;

    if(GlobalService.isAutenticado()) 
    {
        return <Route {...rest} componen={children}/>;
    }

    return <Route {...rest} component={renderComponentLogin} />;

}

export default PrivateRoute;