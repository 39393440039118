import { Evento } from 'passemix-lib/src/models/Evento';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EventoService } from '../../services/EventoService';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import ImageGeneric from '../../content/img/generic.png'

const EventosComponent: React.FC = () => {

    const [eventos, setEventos] = useState<Evento[]>(new Array<Evento>());

    const history = useHistory();
    useEffect(() => { carregaEventos(); }, [])

    const carregaEventos = async () => {
        try {
            let service = new EventoService();
            let response = await service.obterEventosLoja();

            setEventos(response);
        }
        catch (error) {
            console.log(error);
        }
    }
    return <>
        <div className="grid">
            <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
                {
                    eventos && eventos.length > 0 ?
                        <>
                            <div className="p-grid p-mt-3">
                                {
                                    eventos.map((evento, i) =>
                                        <div className="p-col-12 p-md-12 p-lg-6 p-xl-4 pointer" key={i} onClick={() => history.push(`/${evento.url}`)} >
                                            <div className="card-evento">
                                                <div className="card-evento-imagem" style={{ backgroundImage: `url(${(evento.imagem) ? `https://passemix-assets.s3.amazonaws.com/eventos/${evento.imagem}` : ImageGeneric})` }}></div>
                                                <div className="card-evento-texto">
                                                    <div className="titulo">
                                                        <span>{evento.nome}</span>
                                                    </div>
                                                    <div className="data">
                                                        <span>{UtilsHelper.formatDateTime(evento.data, `dd 'de' MMMM yyyy '•' HH:mm`)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                        : ''
                }
            </div>
        </div>
    </>;
}

export default EventosComponent;