import axios from "axios";
import { crc16ccitt } from 'crc';
import qrcode, { QRCodeToDataURLOptions } from 'qrcode';
import { ResponseHelper } from "../helpers/ResponseHelper";

export class UtilService {

    public async obterIp(): Promise<any> {
        return await axios.get("https://geolocation-db.com/json/")
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public generateQrCodePix (version: string,
        key: string,
        city: string,
        name: string,
        value: number,
        transactionId?: string,
        message?: string): any {
        const payloadKeyString = this.generateKey(key, message);

        const payload: string[] = [
            this.genEMV('00', version),
            this.genEMV('26', payloadKeyString),
            this.genEMV('52', '0000'),
            this.genEMV('53', String(986)),
        ];

        if (value) {
            payload.push(this.genEMV('54', value.toFixed(2)));
        }

        name = String(name)
            .substring(0, 25)
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

        city = String(city)
            .substring(0, 15)
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

        payload.push(this.genEMV('58', 'BR'!.toUpperCase()));
        payload.push(this.genEMV('59', name));
        payload.push(this.genEMV('60', city));

        payload.push(this.genEMV('62', this.genEMV('05', transactionId!)));

        payload.push('6304');

        const stringPayload = payload.join('');
        const crcResult = crc16ccitt(stringPayload).toString(16).toUpperCase().padStart(4, '0');

        const payloadPIX = `${stringPayload}${crcResult}`;

        return {
            payload: () => payloadPIX,
            base64: (options?: QRCodeToDataURLOptions) => qrcode.toDataURL(payloadPIX, options),
        };
    }

    private generateKey(key: string, message?: string): string {
        const payload: string[] = [this.genEMV('00', 'BR.GOV.BCB.PIX'), this.genEMV('01', key)];
        if (message) {
            payload.push(this.genEMV('02', message));
        }
        return payload.join('');
    }

    private genEMV(id: string, parameter: string): string {
        const len = parameter.length.toString().padStart(2, '0');
        return `${id}${len}${parameter}`;
    }
}