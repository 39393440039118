import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UsuarioService } from '../../services/UsuarioService';
import { GlobalService } from '../../services/GlobalService';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../helpers/GlobalContext';
import logo from '../../content/img/logo-azul.svg';
import './login.css';
import { Cliente } from 'passemix-lib/src/models/Cliente';
import EmailForm from './EmailForm';
import SenhaForm from './SenhaForm';
import { ClienteService } from '../../services/ClienteService';
import { Button } from 'primereact/button';
import CadastroForm from './CadastroForm';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Divider } from 'primereact/divider';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { LoginResponse } from 'passemix-lib/src/models/LoginResponse';
import Recaptcha from 'react-recaptcha';

declare var window: any;

export enum EnumLoginAcoes {
    Email,
    Cadastro,
    Senha
}

export interface ILoginComponent {
	
}

const LoginComponent: React.FC<ILoginComponent> = (props) => {

	let recaptchaInstace;
	const history = useHistory();
	const [loading, setLoading] = useState(false);
  	const [erro, setErro] = useState<string>();
	const {setLogado} = useContext(GlobalContext);
	const [accessTokenFacebook, setAccessTokenFacebook] = useState<string>();
	const [profileFacebook, setProfileFacebook] = useState<any>();
	const [cliente, setCliente] = useState<Cliente>();
	const [acaoAtual, setAcaoAtual] = useState(EnumLoginAcoes.Email);
	const [recaptchaToken, setRecaptchaToken] = useState<string>('');
	const [ano] = useState(format(utcToZonedTime(new Date(), 'America/Sao_Paulo'), "yyyy"));

	const verifyCallback = (response: string) => {
		setRecaptchaToken(response);
	}
	
	const checkEmail = async (parametro: string) => {
		try 
		{
			let service = new ClienteService();
			let response = await service.buscarClientePorEmailOuCpf(parametro);

			if(response !== undefined){
				setCliente(response);
				setAcaoAtual(EnumLoginAcoes.Senha);
			}else{

				let cliente = new Cliente();
				cliente.tokenRecaptcha = recaptchaToken;

				if(parametro.includes('@'))
				{
					cliente.email = parametro;
				} else {
					cliente.cpf = parametro;
				}
				
				setCliente(cliente);
				setAcaoAtual(EnumLoginAcoes.Cadastro);
			}
			
		} catch (error: any) {
			setErro(error.data.Message);
		}
	}

	const processaLogin = useCallback((response: LoginResponse) => {
		GlobalService.setSessao(response);
		let state = history.location.state;

		setLogado(GlobalService.isAutenticado());

		if(response.isComplete !== undefined && !response.isComplete)
		{
			history.push("/conta/confirmacao", "checkout");
		}else {
			switch (state) {
				case 'checkout':
					history.push("/checkout");
					break;
				case 'conta':
					history.push("/meus-ingressos");
					break;
				default:
					history.push("/meus-ingressos");
			}
		}
		
	}, [history, setLogado])

	const login = async (senha: string) => {
		try 
		{
			setErro(undefined);
			
			let service = new UsuarioService();
			let response = await service.autenticar({
				email: cliente!.email,
				senha: senha
			});

			processaLogin(response);
		}
		catch (error: any) {
			setErro(error.data.Message);
		}
	}

	const recuperaSenha = async () => {
		try {
			let service = new UsuarioService();
            await service.recuperarSenha({email: cliente!.email});
                
			toast.success("Enviamos as instruções para seu email.");
		} catch (error: any) {
			console.log(error.data.Message)
		}
	}

	const resetProfile = () => {
		setAcaoAtual(EnumLoginAcoes.Email);
		setCliente(undefined);
		setErro(undefined);
	}

	const cadastroSalvo = (cliente: Cliente) => {
		
		if(cliente !== undefined){
			Swal.fire({
				title: 'Pronto!',
				text: "Agora é só fazer o login.",
				icon: 'success',
				showCancelButton: false,
				confirmButtonColor: '#004399',
			})
	
			setAcaoAtual(EnumLoginAcoes.Senha);
		}else{
			setErro("Erro ao realizar o cadastro. Tente novamente");
			setAcaoAtual(EnumLoginAcoes.Email);
		}
	}

	const processarLoginFacebook = useCallback(async () => {
		if (accessTokenFacebook !== undefined && profileFacebook !== undefined) 
		{
			let service = new UsuarioService();
			let responseLogin = await service.loginFacebook({
				accessTokenFacebook: accessTokenFacebook,
				email: profileFacebook.email,
				nome: profileFacebook.name
			});

			processaLogin(responseLogin);
		}
	}, [accessTokenFacebook, profileFacebook, processaLogin])

	useEffect(() => {
		processarLoginFacebook();
	}, [processarLoginFacebook, accessTokenFacebook, profileFacebook])

	const viewLogin = () => {
        return (
            <React.Fragment>
				<FacebookLogin
					appId="1750605045370766"
					onSuccess={(response) => {
						setLoading(true);
						setAccessTokenFacebook(response.accessToken);
					}}
					onFail={(error) => {
						setErro('Erro ao realizar login');
						console.log('Login Failed!', error);
					}}
					onProfileSuccess={async (response) => {
						setLoading(true);
						setProfileFacebook(response)
					}}
					children="Entre com Facebook"
					className='button-facebook-login'
				/>
				<div className='p-mb-2'>
					<Divider align='center'>
						<span className='text-divider '>ou digite seus dados</span>
					</Divider>
				</div>
				<EmailForm onCheck={checkEmail} />
				<div className="p-text-center">
					{
						erro ? <small className="invalid">{erro}</small> : ''
					}
				</div>
				<div className='p-mb-2'>
					<Divider align='center'>
						<span className='text-divider '>digite seus dados para se cadastrar</span>
					</Divider>
				</div>
            </React.Fragment>
        );
    }

	const viewCadastro = () => {
        return (
            <React.Fragment>
				<CadastroForm cliente={cliente!} onSave={cadastroSalvo} />
            </React.Fragment>
        );
    }

	const viewSenha = () => {
        return (
            <React.Fragment>
				<div className='profile'>
					<div className='nome'>{cliente?.nome}</div>
					<div>{cliente?.email}</div>
					<div>
						<Button label="(Este não é você?)" className="p-button-sm p-button-link button-change-profile" onClick={resetProfile} />
					</div>
				</div>
			
				<SenhaForm onLogin={login} onRecuperarSenha={recuperaSenha} />
				<div className="p-text-center">
					{
						erro ? <small className="invalid">{erro}</small> : ''
					}
				</div>
            </React.Fragment>
        );
    }

	const carregaView = () => {
        let result = <></>

        switch (acaoAtual) {
            case EnumLoginAcoes.Email:
                result = viewLogin();
                break;
            case EnumLoginAcoes.Cadastro:
                result = viewCadastro();
                break;
            case EnumLoginAcoes.Senha:
                result = viewSenha();
                break;
            default:
                result = viewLogin();
                break;
        }

        return result;
    }

	useEffect(() => {
		recaptchaInstace.reset();
		recaptchaInstace.execute();
	}, [recaptchaInstace])

	return (
		<>
			<ToastContainer position="top-right" autoClose={8000} />
			<div className='container-login'>
				<div className='login-content'>
					<div>
						<a href="/"><img className="logo-login" src={logo} alt="Logo" /></a>
					</div>
					{
						loading ? <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i> : carregaView()
					}
					<div className='text-copy'>
						©{ano} PasseMix. Todos os direitos reservados.
					</div>
				</div>
				<Recaptcha
					ref={e => recaptchaInstace = e}
					sitekey="6LcjKukUAAAAAANFEzAWiwLKeBCN4hwlAUjRo1Ab"
					render="explicit"
					size="invisible"
					hl="pt-br"
					verifyCallback={verifyCallback}
					onloadCallback={() => {
						window.grecaptcha.execute();
					}}
				/>
			</div>
		</>
	);
}

export default LoginComponent;