import React, { useContext, useState } from 'react';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { isValid as isValidCpf } from "@fnando/cpf";
import GlobalContext from '../../helpers/GlobalContext';
import { ItemVenda } from 'passemix-lib/src/models/ItemVenda';
import { Ticket } from 'passemix-lib/src/models/Ticket';

export interface IIdentificacaoComponent {
  onChange: any;
}

const IdentificacaoComponent: React.FC<IIdentificacaoComponent> = (props) => {

  const { carrinho, setCarrinho } = useContext(GlobalContext);
  const [itensVenda, setItensVenda] = useState<ItemVenda[]>(carrinho.itens);

  const onChangeModelo = (event: any) => {
    if(itensVenda !== undefined)
    {
      const { name, value } = event.target;

      let tick = itensVenda[name].ticket;
      
      itensVenda[name].ticket = { 
        participante: tick.participante, 
        documento: tick.documento, 
        modelo: value,
        valido: tick.valido
      } as Ticket

      setItensVenda([...itensVenda])
      setCarrinho({...carrinho, itens: itensVenda})
    }
  }

  const onBlurParticipante = () => {
    if(itensVenda !== undefined)
    {
      setCarrinho({...carrinho, itens: itensVenda})
      let identificacoesValida = itensVenda.filter(x => x.ticket.valido).length === carrinho.itens.length;
      props.onChange(identificacoesValida);
    }
  }

  const onChangeParticipante = (event: any) => {
    if(itensVenda !== undefined)
    {
      const { name, value } = event.target;

      let tick = itensVenda[name].ticket;
      
      itensVenda[name].ticket = { 
        participante: value, 
        documento: tick.documento, 
        modelo: tick.modelo,
        valido: tick.valido
      } as Ticket

      setItensVenda([...itensVenda])
    }
  }

  const onBlurCpf = () => {
    if(itensVenda !== undefined)
    {
      setCarrinho({...carrinho, itens: itensVenda})
      let identificacoesValida = itensVenda.filter(x => x.ticket.valido).length === carrinho.itens.length;
      props.onChange(identificacoesValida);
    }
  }

  const onChangeCpf = (event: any) => {
    if(itensVenda !== undefined)
    {
      const { name, value } = event.target;
      let cpfFormatado = UtilsHelper.formatCpf(value);
      
      let tick = itensVenda[name].ticket;
      
      itensVenda[name].ticket = { 
        participante: tick.participante, 
        documento: cpfFormatado, 
        modelo: tick.modelo,
        valido: isValidCpf(value)
      } as Ticket

      setItensVenda([...itensVenda])

      let identificacoesValida = itensVenda.filter(x => x.ticket.valido).length === carrinho.itens.length;
      props.onChange(identificacoesValida);
    }
  }

  return (<div>
    {
      itensVenda && itensVenda.map((item, index) => (
        <Card className="p-shadow-5 p-mb-4" key={index}>
          <div className="p-grid">
            <div className="p-col-12 p-sm-12 p-lg-4 p-xl-4">
              <div className="text-label-info">Lote</div>
              <div className="text-info p-text-nowrap p-text-truncate">{item.lote.nome}</div>
            </div>
            <div className="p-col-6 p-sm-6 p-lg-4 p-xl-4">
              <div className="text-label-info">Tipo</div>
              <div className="text-info p-text-nowrap p-text-truncate">{item.lote.tipoIngresso}</div>
            </div>
            <div className="p-col-6 p-sm-6 p-lg-4 p-xl-4">
              <div className="text-label-info">Setor</div>
              <div className="text-info p-text-nowrap p-text-truncate">{item.lote.setor}</div>
            </div>
            <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
              <div className="label">Nome completo</div>
              <InputText style={{width: '100%'}} name={`${index}`} value={item.ticket.participante} onChange={onChangeParticipante} onBlur={onBlurParticipante} />
            </div>
            <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
              <div className="label">CPF</div>
              <InputText style={{width: '100%'}} name={`${index}`} value={item.ticket.documento} onChange={onChangeCpf} onBlur={onBlurCpf}/>
              {
                !item.ticket.valido ? <small className="invalid">CPF inválido</small> : ''
              }
            </div>
            {
              item.lote.modelo ?
                <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                  <div className="label">{item.lote.rotuloModelo}</div>
                  <Dropdown
                    style={{width: '100%'}}
                    name={`${index}`}
                    value={item.ticket.modelo}
                    options={item.lote.modelos.split(",")}
                    onChange={onChangeModelo}
                    placeholder={`Selecione o ${item.lote.rotuloModelo}`} 
                  />
                  {
                    item.ticket.modelo === '' ? <small className="invalid">Selecione o {item.lote.rotuloModelo}</small> : ''
                  }
                </div> : ''
            }
          </div>
        </Card>
      ))
    }
  </div>);
}

export default IdentificacaoComponent;