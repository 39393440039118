import React from 'react';
import ComissarioComponent from '../components/Comissario/ComissarioComponent';
import LayoutComponent from '../components/Layout/LayoutComponent';

const ComissarioContainer: React.FC = () => {
	

	const conteudo =
		<React.Fragment>
			<ComissarioComponent />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default ComissarioContainer;