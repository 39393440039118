import React from 'react';
import LoginComponent from '../components/Login/LoginComponent';

const LoginContainer: React.FC = () => {

	return (
		<LoginComponent />
	);
}

export default LoginContainer;