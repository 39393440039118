import { Venda } from 'passemix-lib/src/models/Venda';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { VendaService } from '../../services/VendaService';
import { ToastContainer, toast } from 'react-toastify';
import { Skeleton } from 'primereact/skeleton';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { Badge } from 'primereact/badge';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../helpers/GlobalContext';
import Icon from '../../StyleGuide/Components/Icon';
import syncTime from '../../content/img/sync_time.svg';

const VendasComponent: React.FC = () => {

  const history = useHistory();
  const { logout } = useContext(GlobalContext);
  const [showSkelton, setShowSkelton] = useState(false);
  const [vendas, setVendas] = useState<Venda[]>(new Array<Venda>());

  const carregarVendas = useCallback(async () => {
    try {
      setShowSkelton(true);

      let service = new VendaService();
      let response = await service.obterVendasCliente();

      if (response !== undefined)
        setVendas(response);
    }
    catch (error: any) {

      if (error.status === 401) {
        logout();
        history.push("/eventos");
      } else {
        toast.warn('Falha ao carregar compras');
      }
    } finally {
      setShowSkelton(false);
    }

  }, [history, logout]);

  const statusBody = (venda: Venda) => {
    switch (venda.status) {
      case 'Pendente':
        return <Badge value="Pendente" severity="info" />
      case 'Analise':
        return <Badge value="Em analise" severity="info" />
      case 'Aprovada':
        return <Badge value="Aprovada" severity="success" />
      case 'Cancelada':
        return <Badge value="Cancelada" severity="danger" />
      case 'Estornada':
        return <Badge value="Estornada" severity="warning" />
      case 'Rejeitada':
        return <Badge value="Rejeitada" severity="danger" />
      default:
        return <Badge value={venda.status} severity="info" />
    }
  }

  const abrirVenda = (venda: Venda) => {
    if (['Aprovada', 'Emitida'].includes(venda.status))
      history.push(`/compra/${venda.id}`)
  }

  useEffect(() => {
    carregarVendas();
  }, [carregarVendas])

  return (<>
    <ToastContainer position="top-right" autoClose={8000} />
    <div className="p-grid p-m-0">
      <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
        <div className="p-grid">
          <div className="p-d-flex p-ml-2 p-mt-3 p-mb-1">
            <div className='p-pt-1 p-pr-2'>
              <Icon size='menu' name='ticketOutline' color='primary' />
            </div>
            <div className='text-title'>
              Meus ingressos
            </div>
          </div>
          <Divider />
          {
            showSkelton ?
              <>
                <Skeleton height="2rem" className="p-mb-2" />
                <Skeleton height="2rem" className="p-mb-2" />
                <Skeleton height="2rem" className="p-mb-2" />
                <Skeleton height="2rem" className="p-mb-2" />
              </> : ''
          }
          {
            vendas.length > 0 ? vendas.map((venda, i) =>
              <div className="p-col-12 p-mt-2 pointer" key={i} onClick={() => abrirVenda(venda)}>
                <Card className="p-shadow-5">
                  <div className="p-grid p-m-0">
                    <div className='p-col-12'>
                      <div className="p-d-flex p-jc-between text-infos-order p-pb-2">
                        <div className="text-name-event-order p-pr-3">{venda.evento.nome}</div>
                        <div className="text-status-order">{statusBody(venda)}</div>
                      </div>
                      <Divider type='dashed' />
                      <div className="p-d-flex p-jc-between text-infos-order p-pt-2">
                        <div>#{venda.id}</div>
                        <div>{venda.meioPagamento}</div>
                        <div>{UtilsHelper.formatDateTime(venda.data, 'dd/MM/yyyy')}</div>
                        <div>R$ {UtilsHelper.formatCurrency(venda.total)}</div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )
            : ''
          }
          {
            !showSkelton && vendas.length === 0 ?
            <div className='p-col-12 p-text-center'>
              <div>
                <img className='p-m-3' style={{width: "200px"}} src={syncTime} alt="sync" />
              </div>
              <h3>Você ainda não comprou nenhum ingresso.</h3>
            </div>
            : ''
          }
        </div>
      </div>
    </div>
  </>);
}

export default VendasComponent;