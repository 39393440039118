import React, { useCallback, useContext, useEffect, useState } from 'react';
import { parseISO, differenceInMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz';
import { Venda } from 'passemix-lib/src/models/Venda';
import { useHistory } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { VendaService } from '../../services/VendaService';
import { Button } from 'primereact/button';
import { UtilsHelper } from 'passemix-lib/src/helpers/UtilsHelper';
import { Message } from 'primereact/message';
import FileSaver from 'file-saver';
import { Dialog } from 'primereact/dialog';
import GlobalContext from '../../helpers/GlobalContext';
import TrocaParticipanteForm from './TrocaParticipanteForm';
import { Ticket } from 'passemix-lib/src/models/Ticket';
import {QRCodeSVG} from 'qrcode.react';
import './ticket.css';
import { ToastContainer, toast } from 'react-toastify';

export interface IVendaComponent {
	idVenda: string;
}

const VendaComponent: React.FC<IVendaComponent> = (props) => {

	const history = useHistory();
	const {logout} = useContext(GlobalContext);
	const [showAlteraParticipante, setShowAlteraParticipante] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [venda, setVenda] = useState<Venda>();
	const [ticket, setTicket] = useState<Ticket>(new Ticket());
	const [loadingDownload, setLoadingDownload] = useState(false);
	
	const carregarVenda = useCallback(async () => {
		try {

			let service = new VendaService();
			let response = await service.obterPorId(parseInt(props.idVenda));

			setVenda(response);

			if(!['Aprovada', 'Emitida'].includes(response.status))
				history.push("/meus-ingressos");

			let evento = response.evento;

			let agora = utcToZonedTime(new Date(), 'America/Sao_Paulo');
			let dataEvento = parseISO(evento.data.toString());
			
			let diferenca = differenceInMinutes(dataEvento, agora);

			if(diferenca >= 1440)
				setShowAlteraParticipante(true);
		}
		catch (error: any) {

			if(error.status === 401)
			{
				logout();
				history.push("/eventos");
			}else{
				toast.error("Erro ao carregar compra");
			}			
		}

	}, [history, logout, props.idVenda]);

	const verificaParticipantes = useCallback(async () => {
		if(venda)
		{
			if(venda.itensVenda !== undefined)
			{
				venda.itensVenda.forEach(element => {
					if((['Aprovada', 'Emitida'].includes(venda.status)) && element.ticket !== undefined && element.ticket.participante === undefined)
						history.push(`/confirmacao/${venda.id}`);
				});
			}
		}
	}, [history, venda]);

	const baixarIngresso = async () => 
	{
		try {

			if(venda !== undefined)
			{
				setLoadingDownload(true);
				const service = new VendaService();
				const response = await service.downloadPdf(venda?.id);

				if (response !== undefined)
				{
					// Faz download do PDF
					let file = new Blob([response], {type: "application/pdf"});
					FileSaver.saveAs(file, `${venda.id}-ingressos.pdf`);
				}	
			}

		} catch (error) {
			
		}finally{
			setLoadingDownload(false);
		}
	}

	const alterarParticipante = (ticket: Ticket) => {
		setTicket(ticket);
		setShowDialog(true);
	}

	const alteracaoFinalizada = (ticket: Ticket) => {
		carregarVenda();
		setShowDialog(false);
	}

	useEffect(() => {
		carregarVenda();
	}, [carregarVenda])

	useEffect(() => {
		verificaParticipantes();
	}, [verificaParticipantes])


	return (
		<div className="p-grid p-m-0">
			<ToastContainer position="top-right" autoClose={8000} />
			<div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
				<div className="p-d-flex p-jc-between">
					<div className="p-mr-2">
						<h4>Código: {venda?.id}</h4>
					</div>
					<div className="p-mr-2 p-pt-2">
						<Button label="Voltar" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => history.push('/meus-ingressos')} />
					</div>
				</div>
				{
					venda !== undefined && (['Aprovada', 'Emitida'].includes(venda.status)) && venda.itensVenda !== undefined ? 
						<div>
							{
								venda.itensVenda.map((item, i) =>
									item.ticket !== undefined ? 
									<div className='ticket-container' key={i}>
										<div className='ticket'>
											<div className='p-d-flex p-jc-between ticket-header'>
												<div className="text-evento p-text-nowrap p-text-truncate">
													{venda.evento.nome}
												</div>
												<div className='p-text-nowrap p-text-truncate'>
													{UtilsHelper.formatDateTime(venda.evento.data, 'dd/MMMM - HH:mm')}
												</div>
											</div>
											<div className='ticket-content'>
												<div className="p-grid">
													<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
														<div className="text-label-info">Lote</div>
														<div className="ticket-text-value p-text-nowrap p-text-truncate">{item.lote.nome}</div>
													</div>
													<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
														<div className="text-label-info">Tipo</div>
														<div className="ticket-text-value p-text-nowrap p-text-truncate">{item.lote.tipoIngresso}</div>
													</div>
													<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
														<div className="text-label-info">Setor</div>
														<div className="ticket-text-value p-text-nowrap p-text-truncate">{item.lote.setor}</div>
													</div>
													<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
														<div className="text-label-info">Valor</div>
														<div className="ticket-text-value p-text-nowrap p-text-truncate">R$ {UtilsHelper.formatCurrency(item.lote.preco)}</div>
													</div>
													<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
														<div className="text-label-info">Participante</div>
														<div className="ticket-text-value p-text-nowrap p-text-truncate">{item.ticket.participante}</div>
													</div>
													<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
														<div className="text-label-info">Documento</div>
														<div className="ticket-text-value p-text-nowrap p-text-truncate">{item.ticket.documento}</div>
													</div>
													{
														item.lote.modelo ?
														<div className="p-col-6 p-sm-6 p-lg-6 p-xl-3">
															<div className="text-label-info">{item.lote.rotuloModelo}</div>
															<div className="ticket-text-value p-text-nowrap p-text-truncate">{item.ticket.modelo}</div>
														</div> : ''
													}
													{
														item.lote.extras ?	
														<div className='p-col-12'>
															<div className="text-label-info">Informações</div>
															<div className="text-info"><small>{item.lote.extras}</small></div>
														</div>: ''
													}
												</div>
											</div>
											<div className='rip'></div>
											{
												showAlteraParticipante && !item.ticket.participanteAlterado ?
												<>
													<div className='alterar-participante'>
													<Button label="Alterar Participante" className="p-button-danger p-button-sm p-mt-1" onClick={() => {alterarParticipante(item.ticket)}} />
													</div>
													<div className='rip'></div>
												</>
												 : ''
											}
											<div className='ticket-subdetail p-text-center'>
												<QRCodeSVG value={item.ticket.codBarras} className='img-fluid'/>
											</div>
										</div>
									</div>
									: ''
								)
							}
							<div className="p-col-12 p-text-center">
								{/* <Button 
									label="Baixar Ingressos" 
									className="p-button-primary p-button-md p-mt-4" 
									onClick={baixarIngresso}
									disabled={loadingDownload}
									loading={loadingDownload}
								/> */}
							</div>
						</div>
						: ''
				}

				<Dialog header="Atenção" visible={showDialog} style={{ width: '100vh' }} resizable={false} draggable={false} onHide={() => setShowDialog(false)}>
					<Message severity="warn" text="Troca de titularidade pode ser realizada apenas uma vez por ingresso." />
					<Divider />
					<TrocaParticipanteForm ticket={ticket} onSave={alteracaoFinalizada} />
				</Dialog>
				<div className="p-col-12 p-pt-3">
					<Divider align="left">
						<span className="text-dividers">Políticas sobre ingressos</span>
					</Divider>
					<div className="text-evento-politica">
						<ul>
							<li>Em caso de arrependimento da compra, o reembolso do valor do ingresso somente será efetuado caso a solicitação seja feita no prazo de até 7 (sete) dias a contar da data da compra, e desde que realizado o pedido de devolução com, no máximo, 48 (quarenta e oito) horas de antecedência do horário de início do evento. Essa solicitação deve ser feita pelo titular da compra através do e-mail: contato@passemix.com.br;</li>
							<li>Em caso de cópias do ingresso, o sistema de segurança validará apenas o primeiro acesso. Após validado uma vez, o voucher não poderá ser reutilizado;</li>
							<li>A PasseMix não se responsabiliza por ingressos adquiridos em pontos de venda não oficiais;</li>
							<li>O ingresso adquirido é válido somente para o dia, hora, local para o qual foi emitido;</li>
							<li>Dúvidas poderão ser encaminhadas para contato@passemix.com.br;</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VendaComponent;